import React from "react"
import { t } from "i18next"
import { styled } from "styled-components"

// Components
import { Box, BoxProps, LabelTextWithLeftArrow } from "@project/common"

// types
type LabelTextProps = {
  label: string
  labelWidth?: string
  labelMinWidth?: string
  labelMaxWidth?: string
  labelClassName?: string
  children: React.ReactNode
} & BoxProps

// styles
const StyledFlex = styled(Box)<{
  $labelWidth?: string
  $labelMinWidth?: string
  $labelMaxWidth?: string
}>`
  .label-with-arrow {
    ${(props) => (props?.$labelWidth ? `width:${props?.$labelWidth};` : "")}
    ${(props) =>
      props?.$labelMinWidth ? `min-width:${props?.$labelMinWidth};` : ""}
    ${(props) =>
      props?.$labelMaxWidth ? `max-width:${props?.$labelMaxWidth};` : ""}
  }
`

// ! ## Main component
export const OperationFields = ({
  label,
  children,
  labelWidth,
  labelMaxWidth,
  labelMinWidth,
  labelClassName,
  align = "center",
  ...rest
}: LabelTextProps) => {
  return (
    <StyledFlex
      display={"flex"}
      align={align}
      $labelMaxWidth={labelMaxWidth}
      $labelMinWidth={labelMinWidth}
      $labelWidth={labelWidth}
      {...rest}
    >
      <LabelTextWithLeftArrow
        width={labelWidth}
        label={t(label)}
        className={`label-with-arrow ${labelClassName}`}
      />
      {children}
    </StyledFlex>
  )
}
