// theme
import { theme } from "@project/common"

// packages
import { styled } from "styled-components"
import { ServiceProvisionRecordBottomDisplayProps } from "../../../types/serviceRecordProvision"

export const ServiceProvDetailsOperationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  .operation_inputs {
    flex: 1;
    min-width: 300px;
  }
`

export const RecordListSheetWrapper = styled.div`
  &.has-border {
    border: 1px solid ${theme.colors.border};
    padding: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media print {
      border: none;
      padding: 2px;
      @page {
        margin: 14px 25px !important;
      }
    }
  }
  .screen-media-table {
    @media print {
      display: none;
    }
  }
  .time-cell,
  .align-center {
    text-align: center;
  }
  .ant-table .ant-table-container {
    border-color: ${theme.colors.text} !important;
    border-left: 0 !important;
    border-radius: 0 !important;
    table {
      border-collapse: collapse;
      tbody > tr > td.ant-table-cell.past-cell {
        border-right: 1px dashed black;
      }
      tfoot > tr > td.ant-table-cell,
      tfoot > tr > th.ant-table-cell,
      thead > tr > td.ant-table-cell,
      thead > tr > th.ant-table-cell,
      tbody > tr > td.ant-table-cell,
      tbody > tr > th.ant-table-cell {
        padding: 2px 4px !important;
        border-color: ${theme.colors.text} !important;
        font-size: 8.032pt !important;
        text-align: center;
        line-height: 14.59px;
        font-weight: 400 !important;
        span {
          font-size: 8.032pt !important;
        }
      }
      thead > tr:first-child > th.ant-table-cell {
        &:first-child {
          border-left: 1px solid ${theme.colors.text} !important;
        }
      }
      tbody > tr > td.ant-table-cell:first-child {
        border-left: 1px solid ${theme.colors.text} !important;
      }

      /* Style for all the empty rows */
      tbody > tr.empty-row > td.ant-table-cell {
        .insert-space {
          visibility: hidden;
          font-size: inherit !important;
        }
        background-color: ${theme.colors.disabled};
      }
    }
    table > tbody > tr > th.ant-table-cell,
    table > thead > tr > th.ant-table-cell {
      background: ${theme.colors.border} !important;
      &:last-child,
      &:first-child {
        border-radius: 0 !important;
      }
    }

    /* Style for footer [total-representing] row */
    table > tbody.footer-total-tbody {
      tr.total > th.ant-table-cell,
      tr.total > td.ant-table-cell {
        border-top: 1px solid ${theme.colors.text};
        line-height: 14.59px;
        font-size: 8.032pt !important;
        font-weight: 400;
      }

      /* summary-cell, dashed-borders starts */
      tr.total > th.summary-cell.dashed-border {
        border-right: 1px dashed;
        background-color: white !important;
        &.bottom-dashed {
          border-bottom: 1px dashed;
        }
        &.top-dashed {
          border-top: 1px dashed;
        }
      }

      tr.total > td.summary-cell.dashed-border {
        &.bottom-dashed {
          border-bottom: 1px dashed;
        }
        &.top-dashed {
          border-top: 1px dashed;
        }
      }
      /* summary-cell, dashed-borders ends */

      th.total-head {
        border-left: 1px solid ${theme.colors.text};
      }
      &::before {
        line-height: 0.8em;
        content: " ";
        color: red;
        display: block;
        height: 0.2em;
      }
    }
  }

  .pft-container .pft-content {
    table > tbody > tr > td.ant-table-cell.past-cell {
      border-right: 1px dashed black !important;
    }
    table > tbody > tr > td.ant-table-cell.complex-cell {
      border-left: 1px dashed black !important;
    }

    table > thead > tr > th,
    table > tbody > tr > td,
    table > tfoot > tr > td,
    table > tfoot > tr > th,
    table > thead > tr > td,
    table > tbody > tr > th {
      padding: 0.25rem;
    }
    .footer-total-tbody {
      break-before: avoid;
      break-after: avoid;
      break-inside: avoid;
      tr.total > th {
        background-color: ${theme.colors.border};
      }
      td.ant-table-cell {
        text-align: center;
      }
      &::before {
        line-height: 0.8em;
        content: " ";
        color: red;
        display: block;
        height: 0.2em;
      }
    }

    /* Style for footer [total-representing] row */
    table > tbody.footer-total-tbody {
      /* summary-cell, dashed-borders starts */
      tr.total > th.summary-cell.dashed-border {
        border-right: 1px dashed !important;
        background-color: white !important;
        &.bottom-dashed {
          border-bottom: 1px dashed;
        }
        &.top-dashed {
          border-top: 1px dashed;
        }
      }

      tr.total > td.summary-cell.dashed-border {
        border-left-style: dashed;
        &.bottom-dashed {
          border-bottom: 1px dashed;
        }
        &.top-dashed {
          border-top: 1px dashed;
        }
      }
      /* summary-cell, dashed-borders ends */
    }
  }

  /* style for TFooter  */
  .ant-table-footer,
  .pft-footer {
    padding: 0;
    border: 0 !important;
    border-radius: 0;
    margin-top: 6px;
  }

  .footer-container {
    width: 100%;
    overflow: auto;
    .bottom-section-first {
      @media print {
        margin-top: 0rem !important;
        font-size: 8pt !important;
      }
    }
  }

  @media print {
    page-break-inside: avoid !important;
  }
`

export const TableUpperSection = styled.div`
  overflow: auto;
  width: 100%;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    break-before: avoid;
    break-after: avoid;
    width: 100%;
    min-width: 500px;
    thead .guardian-child-name,
    thead .office-number {
      min-width: 80px;
    }

    @media print {
      min-width: 600px;
      thead .office-number {
        min-width: 40px;
      }
    }
  }
  table > thead > tr > th {
    background-color: ${theme.colors.border};
  }

  table > thead > tr > th,
  table > thead > tr > td {
    font-size: 7.23pt;
    padding: 2px !important;
    font-weight: 400;
    border: 1px solid ${theme.colors.text};
    span {
      font-size: inherit;
      font-weight: inherit;
    }
  }

  @media print {
    table {
      min-width: 100%;
      width: 100%;
    }
  }
`

export const StyledTFooter = styled.table`
  width: 100% !important;
  min-width: 600px;
  border-collapse: collapse !important;
  border-spacing: 0;
  empty-cells: show;
  break-before: avoid;
  break-after: avoid;
  tbody {
    tr {
      td {
        width: 10% !important;
        padding: 2px 4px;
        border: 1px solid ${theme.colors.text};
        text-align: center;
        font-size: 8.032pt !important;
        color: ${theme.colors.text} !important;
      }
    }
  }
`

export const StyleCellEditButton = styled.button`
  border: none;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.border};
  @media print {
    display: none;
  }
`

export const HowManyOutOfHowManyFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  .column-table {
    width: 50%;
    float: right;
    border-collapse: collapse;
    td {
      border: 1px solid #000;
      text-align: center;
      min-width: 20px;
      font-size: 8.032pt !important;
      color: ${theme.colors.text} !important;
    }
  }
`

export const ConfirmationContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 8.032pt !important;
  color: ${theme.colors.text} !important;
  margin: 10px 0;
`

export const ParentConfirmationContent = styled.div<ServiceProvisionRecordBottomDisplayProps>`
  width: 100%;
  display: flex;
  margin: 15px 0;
  div {
    font-size: 12px;
    padding-bottom: 5px;
  }
  .child-name,
  .parent-signature {
    width: ${(props) =>
      props.number_of_sections > 1
        ? "calc(50% - 12px - 10%)"
        : "calc(50% - 12px)"};
    border-bottom: 1px solid ${theme.colors.border};
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  .bottom-date {
    text-align: right;
    width: 20%;
    margin-right: 10px;
    border-bottom: 1px solid ${theme.colors.border};
  }
`

export const ServiceProvisionContainer = styled.div`
  @media print {
    .ant-card-head {
      display: none;
    }
  }
`

export const ServiceRecordTabContainer = styled.div`
  #tabs-header_content {
    width: fit-content;
    border: 1px solid ${theme.colors?.action};
    padding: 5px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @media print {
      display: none;
    }
  }
  @media print {
    .ant-tabs-nav {
      display: none;
    }
    .ant-tabs-content-holder {
      border: none;
      padding: 0px;
    }
    table {
      max-width: 100% !important;
    }
  }
`

export const DigitalSignImage = styled.img`
  max-height: 26px;
  width: auto;
`

export const ErrorAlertContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: ${theme.colors.error};
  font-weight: bold;
  padding: 20px;
  border: 1px solid ${theme.colors.error};
  border-radius: 12px;
  @media print {
    display: none;
  }
`
