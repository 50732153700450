import React, { useMemo, useState } from "react"

// packages
import { t } from "i18next"
import { Flex, Popover, Typography } from "antd"

// components
import {
  Box,
  rem,
  theme,
  SelectInput,
  CALENDAR_HOURS_MINUTES,
} from "@project/common"

// hooks
import { useEditPopover } from "../hook/useEditPopover"

// styles
import { StyleCellEditButton } from "./ServiceProvDetailsInternal.styles"

// types
import { EditableCellProps } from "../../../types/serviceRecordProvision"

// ! ## Editable cell component
export const EditableCell: React.FC<EditableCellProps> = ({
  children,
  cellToEdit,
  currentRecord,
  digitalSignType,
}) => {
  const {
    ServiceProvStatusEditContent,
    StartTimeEditContent,
    EndTimeEditContent,
    PastEditContent,
    ComplexEditContent,
    NumberOfHoursEditContent,
    MedicalCooperationEditContent,
    DigitalSignatureEditContent,
    TrialSupportEditContent,
    RegionalCollaborationEditContent,
    RegionalCollaborationMeetingImplementationEditContent,
    CommutingTrainingEditContent,
    TransitionPreparationEditContent,
    IntensiveSupportEditContent,
    EmergencyAcceptanceEditContent,
    RemarkEditContent,
  } = useEditPopover({ currentRecord, digitalSignType })

  const [popoverVisible, setPopoverVisible] = useState(false)

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible)
  }

  const closePopover = () => {
    setPopoverVisible(false)
  }

  const content = useMemo(() => {
    switch (cellToEdit) {
      case "SERVICE_PROVISION":
        return ServiceProvStatusEditContent
      case "START_TIME":
        return StartTimeEditContent
      case "END_TIME":
        return EndTimeEditContent
      case "PAST":
        return PastEditContent
      case "COMPLEX":
        return ComplexEditContent
      case "NUMBER_OF_HOURS":
        return NumberOfHoursEditContent
      case "MEDICAL_COOPERATION":
        return MedicalCooperationEditContent
      case "DIGITAL_SIGNATURE":
        return <DigitalSignatureEditContent closePopover={closePopover} />
      case "TRIAL_SUPPORT_ADDITION":
        return TrialSupportEditContent
      case "REGIONAL_COLLABORATION_ADDITION":
        return RegionalCollaborationEditContent
      case "REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION":
        return RegionalCollaborationMeetingImplementationEditContent
      case "COMMUTING_TRAINING":
        return CommutingTrainingEditContent
      case "TRANSITION_PREPARATION_ADDITION":
        return TransitionPreparationEditContent
      case "INTENSIVE_SUPPORT_ADDITION":
        return IntensiveSupportEditContent
      case "EMERGENCY_ACCEPTANCE_ADDITION":
        return EmergencyAcceptanceEditContent
      case "REMARK":
        return RemarkEditContent
    }
  }, [cellToEdit, currentRecord])
  return (
    <Box
      display={"flex"}
      align={"center"}
      justify={"space-between"}
      gap={"10px"}
    >
      <span
        style={{
          flex: 1,
        }}
      >
        {children}
      </span>

      <Popover
        arrow={false}
        content={content}
        trigger={"click"}
        visible={popoverVisible}
        onVisibleChange={handleVisibleChange}
        overlayInnerStyle={{
          padding: "18px",
          border: `1px solid ${theme.colors.gray5}`,
          overflow: "hidden",
        }}
        overlayStyle={{
          maxWidth: "max-content",
          minWidth: "max-content",
        }}
      >
        <StyleCellEditButton>
          <img
            src={"/assets/icons/edit-icon-black-strock.svg"}
            alt={"icon"}
            style={{
              height: "14px",
            }}
          />
        </StyleCellEditButton>
      </Popover>
    </Box>
  )
}

// ! ## Time select component for start and end time [Reusable COMPONENT]
export const TimeSelect = ({
  min,
  hr,
  onChange,
  label,
}: {
  min: string
  hr: string
  onChange: (val: { min: string; hr: string }) => void
  label?: string
}) => {
  return (
    <Flex align={"center"} gap={rem("10px")}>
      {label && (
        <Typography.Text>
          {t(label)}
          {": "}
        </Typography.Text>
      )}
      <Flex align={"center"} gap={rem("4px")}>
        <SelectInput
          width={"60px"}
          height={"40px"}
          placeholder={"--"}
          name={"work_time_to_hour"}
          value={hr}
          options={CALENDAR_HOURS_MINUTES?.hours.slice(1)}
          onChange={(val) => onChange({ min, hr: val })}
        />
        {t("hr")}
        <SelectInput
          width={"60px"}
          height={"40px"}
          placeholder={"--"}
          name={"work_time_to_min"}
          value={min}
          options={CALENDAR_HOURS_MINUTES?.minutes.slice(1)}
          onChange={(val) => onChange({ min: val, hr })}
        />
        {t("min")}
      </Flex>
    </Flex>
  )
}
