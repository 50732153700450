/* stylelint-disable no-descending-specificity */

import { Box, theme } from "@project/common"
import { Collapse } from "antd"
import styled from "styled-components"

export const DigitalSignImage = styled.img`
  max-height: 26px;
  width: auto;
`

export const PDFContainer = styled.div`
  .table-wrap {
    page-break-inside: avoid;

    .performance-record-container {
      margin-bottom: 0px !important;

      .top-comp-table {
        table.table tr:first-child {
          th,
          td {
            border-top: 2px solid black;
          }
          th:first-child {
            border-left: 2px solid black;
          }
          td:last-child {
            border-right: 2px solid black;
          }
        }

        table tr:last-child {
          th,
          td {
            border-bottom: 2px solid black;
          }
          th:first-child {
            border-left: 2px solid black;
          }
          td:last-child {
            border-right: 2px solid black;
          }
        }
      }
    }

    .pft-container {
      .pft-content {
        .pft-thead tr:first-child th.pft-cell {
          border-top: 2px solid black;
          &:first-child {
            border-left: 2px solid black;
          }
          &:last-child {
            border-right: 2px solid black;
          }
        }

        .pft-tbody tr.ant-table-row {
          td.ant-table-cell {
            &:first-child {
              border-left: 2px solid black;
            }
            &:last-child {
              border-right: 2px solid black;
            }
          }
        }

        tbody.ant-table-tbody.footer-total-tbody {
          tr.total {
            &:first-child {
              th.total-head {
                border-left: 2px solid black;
                border-bottom: 2px solid black;
              }
              td.ant-table-cell:not(.summary-cell) {
                border-bottom: 2px solid black;
              }
              td.summary-cell {
                border-right: 2px solid black;
              }
            }

            &:last-child {
              .summary-cell {
                border-bottom: 2px solid black;
                &:last-child {
                  border-right: 2px solid black;
                }
              }
            }
          }
        }
      }
    }

    & * {
      font-size: 8pt !important;
    }

    .pft-tbody {
      tr.ant-table-row {
        page-break-inside: avoid;
      }
    }

    .ant-table-tbody.footer-total-tbody {
      page-break-inside: avoid;
    }

    .pft-footer {
      .footer-container {
        page-break-inside: avoid;
        table.footer-table {
          tbody {
            tr {
              &:first-child {
                td {
                  border-top: 2px solid black;
                }
                td:nth-of-type(4),
                td:nth-of-type(5),
                td:nth-of-type(6),
                td:nth-of-type(7) {
                  border-bottom: 2px solid black;
                }
              }

              &:last-child {
                td {
                  border-bottom: 2px solid black;
                }
              }

              td {
                &:first-child {
                  border-left: 2px solid black;
                }
                &:last-child {
                  border-right: 2px solid black;
                }
              }
            }
          }
        }
      }
    }

    .bottom-section-first {
      margin-top: 2px !important;
    }
  }
`

export const AlertBoxCollapse = styled(Collapse)`
  margin-top: 20px;
  &.collapse-alert-box .ant-collapse-item {
    * {
      color: ${theme.colors.error};
      font-size: 14px;
    }
    .ant-collapse-expand-icon {
      padding-inline-end: 0px;
      transition: all;
      transform: rotate(0deg);
    }
    .ant-collapse-header-text {
      margin-left: 10px;
    }
    &.ant-collapse-item-active {
      .ant-collapse-expand-icon {
        transform: rotate(180deg);
      }
    }
    .ant-collapse-header {
      padding: 0;
    }
  }
  .ant-collapse-content .ant-collapse-content-box {
    padding: 0 !important;
  }
`

export const AlertBox = styled(Box)`
  border-radius: 12px;
  margin-block: 28px;
  border: 1px solid ${theme.colors.error};

  .alert-box-text {
    font-weight: 700;
    font-size: 16px;
  }

  .first-text {
    margin-left: 8px;
  }

  .alert-table {
    border-collapse: collapse;
    width: 100%;
    td {
      border: ${theme.border.default} !important;
      padding: 8px;
      color: #000 !important;
    }
  }

  @media print {
    display: none;
  }
`
