import React, { useCallback, useState } from "react"
// packages
import { t } from "i18next"
import { Flex, Input, Typography } from "antd"

// components
import {
  Box,
  rem,
  Button,
  ActionBtns,
  RadioGroup,
  SelectInput,
  CheckBox,
  InputField,
  MAX_TRIAL_USE_SUPPORT_COUNT_MONTH,
  USER_ATTENDANCE_VALUES,
  theme,
  MAX_INTENSIVE_SUPPORT_COUNT_MONTH,
} from "@project/common"
import { TimeSelect } from "../ServiceProvDetailsInternal"

// context
import { useServiceProvisionDetail } from "../../../context/ServiceProvisionDetailContext"

// utils
import { getSplittedTime } from "../utils/getSplittedTime"

// constants
import {
  REMARK_CONTENT_VALUES,
  YES_NO_LIST,
} from "@project/common/src/constants"

// types
import {
  Past,
  Time,
  Complex,
  NumberOfHours,
  SPDTableColumns,
  MedicalCooperation,
  ServiceRecordDigitalSign,
} from "../../../types/serviceRecordProvision"
import {
  ADDITIONAL_ITEM_TYPE_VALUES,
  ADDITIONAL_ITEM_YES_NO_OPTIONS,
  MEDICAL_COLLABORATION_SYSTEM,
  MEDICAL_COLLABORATION_SYSTEM_VALUES,
  PICK_DROP_OPTIONS,
  PICK_DROP_VALUES,
  REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION_OPTIONS,
} from "../../../constants"
import { SignatureCanvas } from "../../../components"

const getOptionsWithTranslatedLabel = (
  options: Array<{ label: string; value: any }>,
) => {
  return options.map((option) => {
    option.label = t(option.label)
    return option
  })
}

// ! ## edit popover hook for all the edit content components
export const useEditPopover = ({
  currentRecord,
  digitalSignType = "name",
}: {
  currentRecord: SPDTableColumns
  digitalSignType?: "date" | "name" | "both"
}) => {
  const {
    updateServiceProvisionStatus,
    updateStartTime,
    updateEndTime,
    updatePast,
    updateComplex,
    updateNumberOfHours,
    updateMedicalCooperation,
    updateDigitalSignature,
    updatingServiceProvision,
    updateTrialSupportAddition,
    updateRegionalCollaborationAddition,
    updateRegionalCollaborationMeetingImplementationAddition,
    updateCommutingTrainingAddition,
    updateTransitionPreparation,
    updateIntensiveSupportAddition,
    updateEmergencyAcceptance,
    updateRemark,
  } = useServiceProvisionDetail()

  // ! ## All the edit content components
  // 1. Service provision status edit content [COMPONENT]
  const ServiceProvStatusEditContent = useCallback(() => {
    const [currentServiceProvStatus, setCurrentServiceProvStatus] =
      React.useState<number | string>(
        currentRecord?.attendance?.attendance_type,
      )
    const [trialSupportMonthCount, setTrialSupportMonthCount] =
      React.useState<number>(
        currentRecord?.attendance?.trial_use_support_count_for_month,
      )
    const [communityLifeSupportBase, setCommunityLifeSupportBase] =
      React.useState<boolean>(
        currentRecord?.attendance?.community_life_support_base || false,
      )

    return (
      <Box display={"flex"} direction={"column"} gap={"md"} maw={500}>
        <SelectInput
          options={currentRecord?.attendanceOptions}
          value={currentServiceProvStatus}
          onChange={(value) => {
            if (value == USER_ATTENDANCE_VALUES.TRIAL_SUPPORT) {
              setTrialSupportMonthCount((prev) => prev + 1)
            } else {
              if (
                currentServiceProvStatus.toString() ==
                USER_ATTENDANCE_VALUES.TRIAL_SUPPORT
              )
                setTrialSupportMonthCount((prev) => prev - 1)
            }
            setCurrentServiceProvStatus(value)
          }}
          name={"attendance_type"}
        />
        {currentServiceProvStatus.toString() ==
          USER_ATTENDANCE_VALUES.TRIAL_SUPPORT && (
          <CheckBox
            label={t("Community life support base")}
            checked={communityLifeSupportBase}
            onChange={(e) => setCommunityLifeSupportBase(e.target.checked)}
          />
        )}
        <div style={{ color: theme.colors.error }}>
          {trialSupportMonthCount > MAX_TRIAL_USE_SUPPORT_COUNT_MONTH &&
            t(
              "*Already did {{number}} times this month so you can not get further addition",
              { number: MAX_TRIAL_USE_SUPPORT_COUNT_MONTH },
            )}
        </div>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            loading={updatingServiceProvision}
            onClick={() => {
              updateServiceProvisionStatus(currentRecord.date, {
                attendance_type: currentServiceProvStatus,
                community_life_support_base: communityLifeSupportBase,
              })
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 2. Start time edit content [COMPONENT]
  const StartTimeEditContent = useCallback(() => {
    const [startTime, setStartTime] = React.useState<Time>({
      hr: getSplittedTime(currentRecord.start_time).hr,
      min: getSplittedTime(currentRecord.start_time).min,
    })

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <TimeSelect
          min={startTime.min}
          hr={startTime.hr}
          onChange={(val) => setStartTime(val)}
          label={"Start Time"}
        />
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateStartTime(currentRecord.date, startTime)
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 3. End time edit content [COMPONENT]
  const EndTimeEditContent = useCallback(() => {
    const [endTime, setEndTime] = React.useState<Time>({
      hr: getSplittedTime(currentRecord.end_time).hr,
      min: getSplittedTime(currentRecord.end_time).min,
    })

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <TimeSelect
          min={endTime.min}
          hr={endTime.hr}
          onChange={(val) => setEndTime(val)}
          label={"End Time"}
        />
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateEndTime(currentRecord.date, endTime)
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 4. Past edit content [COMPONENT]
  const PastEditContent = useCallback(() => {
    const [pickupPast, setPickupPast] = React.useState<Past | null>(
      currentRecord.past || null,
    )
    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Pick up")}</Typography.Text>
            <RadioGroup
              options={YES_NO_LIST}
              value={pickupPast?.pickup}
              onChange={(e) => {
                setPickupPast({
                  ...pickupPast,
                  pickup: e.target.value,
                })
              }}
            />
          </Flex>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Location")}</Typography.Text>
            <SelectInput
              width={"150px"}
              height={"40px"}
              placeholder={"--"}
              name={"location"}
              value={
                pickupPast?.location ? pickupPast?.location?.toString() : ""
              }
              options={PICK_DROP_OPTIONS}
              onChange={(val) => {
                setPickupPast({
                  ...pickupPast,
                  location: val,
                })
              }}
            />
          </Flex>
          {pickupPast?.location == PICK_DROP_VALUES.OTHERS && (
            <Flex align={"center"} gap={rem("10px")}>
              <Typography.Text>{t("Location Other")}</Typography.Text>
              <InputField
                value={pickupPast?.location_other}
                onChange={(e) => {
                  setPickupPast({
                    ...pickupPast,
                    location_other: e?.target?.value,
                  })
                }}
              />
            </Flex>
          )}
          <Flex align={"center"} gap={rem("10px")}>
            <TimeSelect
              min={getSplittedTime(pickupPast?.pickup_time).min}
              hr={getSplittedTime(pickupPast?.pickup_time).hr}
              onChange={(val) => {
                setPickupPast({
                  ...pickupPast,
                  pickup_time: `${val.hr}:${val.min}`,
                })
              }}
              label={"Pick-up time"}
            />
          </Flex>
          <Flex align={"center"} gap={rem("10px")}>
            <TimeSelect
              min={getSplittedTime(pickupPast?.arrival_time).min}
              hr={getSplittedTime(pickupPast?.arrival_time).hr}
              onChange={(val) => {
                setPickupPast({
                  ...pickupPast,
                  arrival_time: `${val.hr}:${val.min}`,
                })
              }}
              label={"Arrival time in minutes"}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updatePast(currentRecord.date, pickupPast)
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 5. Complex edit content [COMPONENT]
  const ComplexEditContent = useCallback(() => {
    const [dropOffComplex, setDropOffComplex] = React.useState<Complex | null>(
      currentRecord.complex || null,
    )
    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Drop off")}</Typography.Text>
            <RadioGroup
              options={YES_NO_LIST}
              value={dropOffComplex?.dropOff}
              onChange={(e) => {
                setDropOffComplex({
                  ...dropOffComplex,
                  dropOff: e.target.value,
                })
              }}
            />
          </Flex>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Location")}</Typography.Text>
            <SelectInput
              width={"150px"}
              height={"40px"}
              placeholder={"--"}
              name={"location"}
              value={
                dropOffComplex?.location
                  ? dropOffComplex?.location.toString()
                  : ""
              }
              options={PICK_DROP_OPTIONS}
              onChange={(val) => {
                setDropOffComplex({
                  ...dropOffComplex,
                  location: val,
                })
              }}
            />
          </Flex>
          {dropOffComplex?.location == PICK_DROP_VALUES.OTHERS && (
            <Flex align={"center"} gap={rem("10px")}>
              <Typography.Text>{t("Location Other")}</Typography.Text>
              <InputField
                value={dropOffComplex?.location_other}
                onChange={(e) => {
                  setDropOffComplex({
                    ...dropOffComplex,
                    location_other: e?.target?.value,
                  })
                }}
              />
            </Flex>
          )}
          <Flex align={"center"} gap={rem("10px")}>
            <TimeSelect
              min={getSplittedTime(dropOffComplex?.dropOff_time).min}
              hr={getSplittedTime(dropOffComplex?.dropOff_time).hr}
              onChange={(val) => {
                setDropOffComplex({
                  ...dropOffComplex,
                  dropOff_time: `${val.hr}:${val.min}`,
                })
              }}
              label={"Drop off time"}
            />
          </Flex>
          <Flex align={"center"} gap={rem("10px")}>
            <TimeSelect
              min={getSplittedTime(dropOffComplex?.arrival_time).min}
              hr={getSplittedTime(dropOffComplex?.arrival_time).hr}
              onChange={(val) => {
                setDropOffComplex({
                  ...dropOffComplex,
                  arrival_time: `${val.hr}:${val.min}`,
                })
              }}
              label={"Arrival time in minutes"}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateComplex(currentRecord.date, dropOffComplex)
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 6. Number of hours edit content [COMPONENT]
  const NumberOfHoursEditContent = useCallback(() => {
    const [currentNumberOfHours, setCurrentNumberOfHours] =
      React.useState<NumberOfHours | null>(
        currentRecord.number_of_hours || null,
      )

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>
              {t("Visit support special addition")}
            </Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentNumberOfHours?.special_support_visit}
              onChange={(e) => {
                setCurrentNumberOfHours({
                  ...currentNumberOfHours,
                  special_support_visit: e.target.value,
                })
              }}
            />
          </Flex>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Time")}</Typography.Text>
            <Flex align={"center"} gap={rem("4px")}>
              <TimeSelect
                min={getSplittedTime(currentNumberOfHours?.start_time).min}
                hr={getSplittedTime(currentNumberOfHours?.start_time).hr}
                onChange={(val) => {
                  setCurrentNumberOfHours({
                    ...currentNumberOfHours,
                    start_time: `${val.hr}:${val.min}`,
                  })
                }}
              />
              <Typography.Text>{"~"}</Typography.Text>
              <TimeSelect
                min={getSplittedTime(currentNumberOfHours?.end_time).min}
                hr={getSplittedTime(currentNumberOfHours?.end_time).hr}
                onChange={(val) => {
                  setCurrentNumberOfHours({
                    ...currentNumberOfHours,
                    end_time: `${val.hr}:${val.min}`,
                  })
                }}
              />
            </Flex>
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateNumberOfHours(currentRecord.date, currentNumberOfHours)
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 8. Medical cooperation edit content [COMPONENT]
  const MedicalCooperationEditContent = useCallback(() => {
    const [currentMedicalCooperation, setCurrentMedicalCooperation] =
      React.useState<MedicalCooperation | null>(
        currentRecord.medical_cooperation || null,
      )

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>
              {t("Medical collaboration system")}
            </Typography.Text>
            <SelectInput
              // API removes additional item with value NO
              options={[{ label: "--", value: "NO" }].concat(
                MEDICAL_COLLABORATION_SYSTEM,
              )}
              name={"medial_system_link"}
              placeholder={"--"}
              popupMatchSelectWidth={false}
              value={currentMedicalCooperation?.medical_system_link}
              onChange={(val) => {
                setCurrentMedicalCooperation({
                  ...currentMedicalCooperation,
                  medical_system_link: val,
                  status:
                    val == MEDICAL_COLLABORATION_SYSTEM_VALUES.V
                      ? 0
                      : currentMedicalCooperation.status,
                })
              }}
            />
          </Flex>
          <Flex align={"center"} gap={rem("10px")}>
            <CheckBox
              label={t("Guidance regarding sputum aspiration, etc.")}
              name={"status"}
              checked={currentMedicalCooperation?.status ? true : false}
              disabled={
                MEDICAL_COLLABORATION_SYSTEM_VALUES.V ==
                currentMedicalCooperation.medical_system_link
              }
              onChange={() => {
                setCurrentMedicalCooperation({
                  ...currentMedicalCooperation,
                  status: currentMedicalCooperation?.status ? 0 : 1,
                })
              }}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateMedicalCooperation(
                currentRecord.date,
                currentMedicalCooperation,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 9. Digital signature edit content
  const DigitalSignatureEditContent = useCallback(
    ({ closePopover }: { closePopover?: any }) => {
      return (
        <Box display={"flex"} direction={"column"} gap={"md"}>
          <Box display={"flex"} justify={"space-between"}>
            <Typography.Text>{t("Confirmation signature")}</Typography.Text>
            <Typography.Text
              style={{ cursor: "pointer" }}
              onClick={closePopover}
            >
              {t("X")}
            </Typography.Text>
          </Box>

          <SignatureCanvas
            sign={currentRecord.attendance?.digital_signature_image}
            dateSign={currentRecord.attendance?.digital_signature_date_image}
            signType={digitalSignType}
            onSave={(signature, date) => {
              const currentDigitalSignature: ServiceRecordDigitalSign = {
                date,
                signature,
              }
              updateDigitalSignature(
                currentRecord.date,
                currentDigitalSignature,
              )
            }}
          />
        </Box>
      )
    },
    [],
  )

  // 10. Pop up with yes no option for quick additional item update
  const TrialSupportEditContent = useCallback(() => {
    const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
      React.useState<string | null>(
        currentRecord.trial_support_addition || null,
      )

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Trial use support addition")}</Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentAdditionalItemValue}
              onChange={(e) => setCurrentAdditionalItemValue(e?.target?.value)}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateTrialSupportAddition(
                currentRecord.date,
                currentAdditionalItemValue,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 11. Pop up with yes no option for quick additional item update
  const RegionalCollaborationEditContent = useCallback(() => {
    const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
      React.useState<string | null>(
        currentRecord.regional_collaboration || null,
      )

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Regional collaboration")}</Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentAdditionalItemValue}
              onChange={(e) => setCurrentAdditionalItemValue(e?.target?.value)}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateRegionalCollaborationAddition(
                currentRecord.date,
                currentAdditionalItemValue,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 12. Pop up with yes no option for quick additional item update
  const CommutingTrainingEditContent = useCallback(() => {
    const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
      React.useState<string | null>(currentRecord.commuting_training || null)

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>
              {t("Commuting training addition")}
            </Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentAdditionalItemValue}
              onChange={(e) => setCurrentAdditionalItemValue(e?.target?.value)}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateCommutingTrainingAddition(
                currentRecord.date,
                currentAdditionalItemValue,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 13. Pop up with yes no option for quick additional item update
  const TransitionPreparationEditContent = useCallback(() => {
    const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
      React.useState<string | null>(currentRecord.commuting_training || null)

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>
              {t("Transition preparation support system addition")}
            </Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentAdditionalItemValue}
              onChange={(e) => setCurrentAdditionalItemValue(e?.target?.value)}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateTransitionPreparation(
                currentRecord.date,
                currentAdditionalItemValue,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 14. Pop up with yes no option for quick additional item update
  const IntensiveSupportEditContent = useCallback(() => {
    const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
      React.useState<string | null>(currentRecord.intensive_support || null)
    const [intensiveSupportMonthCount, setIntensiveSupportMonthCount] =
      React.useState<number>(
        currentRecord.attendance?.intensive_support_count_for_month || 0,
      )
    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>{t("Intensive support addition")}</Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentAdditionalItemValue}
              onChange={(e) => {
                if (e.target.value == ADDITIONAL_ITEM_TYPE_VALUES.YES) {
                  setIntensiveSupportMonthCount((prev) => prev + 1)
                } else if (e.target.value == ADDITIONAL_ITEM_TYPE_VALUES.NO) {
                  setIntensiveSupportMonthCount((prev) => prev - 1)
                }
                setCurrentAdditionalItemValue(e?.target?.value)
              }}
            />
          </Flex>
        </Box>
        <div style={{ color: theme.colors.error }}>
          {intensiveSupportMonthCount > MAX_INTENSIVE_SUPPORT_COUNT_MONTH &&
            t(
              "*Already did {{number}} times this month so you can not get further addition",
              { number: MAX_INTENSIVE_SUPPORT_COUNT_MONTH },
            )}
        </div>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateIntensiveSupportAddition(
                currentRecord.date,
                currentAdditionalItemValue,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 15. Pop up with yes no option for quick additional item update
  const EmergencyAcceptanceEditContent = useCallback(() => {
    const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
      React.useState<string | null>(currentRecord.emergency_acceptance || null)

    return (
      <Box display={"flex"} direction={"column"} gap={"md"}>
        <Box direction={"column"} display={"flex"} gap={"sm"}>
          <Flex align={"center"} gap={rem("10px")}>
            <Typography.Text>
              {t("Emergency acceptance addition")}
            </Typography.Text>
            <RadioGroup
              options={ADDITIONAL_ITEM_YES_NO_OPTIONS}
              value={currentAdditionalItemValue}
              onChange={(e) => setCurrentAdditionalItemValue(e?.target?.value)}
            />
          </Flex>
        </Box>
        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateEmergencyAcceptance(
                currentRecord.date,
                currentAdditionalItemValue,
              )
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  // 16. Pop up with regional collaboration meeting implementation I, II NO option for quick additional item update
  const RegionalCollaborationMeetingImplementationEditContent =
    useCallback(() => {
      const [currentAdditionalItemValue, setCurrentAdditionalItemValue] =
        React.useState<string | null>(
          currentRecord.regional_collaboration_meeting_implementation || null,
        )
      return (
        <Box display={"flex"} direction={"column"} gap={"md"}>
          <Box direction={"column"} display={"flex"} gap={"sm"}>
            <Flex
              align={"center"}
              gap={rem("10px")}
              style={{ maxWidth: "500px" }}
            >
              <Typography.Text>
                {t("Regional collaboration meeting implementation addition")}
              </Typography.Text>
              <RadioGroup
                options={getOptionsWithTranslatedLabel(
                  REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION_OPTIONS,
                )}
                value={currentAdditionalItemValue}
                onChange={(e) =>
                  setCurrentAdditionalItemValue(e?.target?.value)
                }
              />
            </Flex>
          </Box>
          <ActionBtns justify={"flex-end"}>
            <Button
              btnText={t("Keep")}
              shape={"round"}
              type={"primary"}
              onClick={() => {
                updateRegionalCollaborationMeetingImplementationAddition(
                  currentRecord.date,
                  currentAdditionalItemValue,
                )
              }}
            />
          </ActionBtns>
        </Box>
      )
    }, [])

  // 17. Pop up with Remark input
  const RemarkEditContent = useCallback(() => {
    const { currentQuery } = useServiceProvisionDetail()

    const [remarkValue, setRemarkValue] = useState(currentRecord?.remarksValue)
    const [insuranceRemarkValue, setInsuranceRemarkValue] = useState(
      currentRecord?.remarksOutput,
    )
    return (
      <Box display={"flex"} direction={"column"} gap={10} w={400}>
        {currentQuery.remark_contents?.includes(
          REMARK_CONTENT_VALUES.REMARKS,
        ) && (
          <div>
            <label htmlFor={"remark"}>{t("Remarks")}</label>
            <Input
              name={"remark"}
              size={"large"}
              id={"remark"}
              value={remarkValue}
              width={200}
              onChange={(e) => setRemarkValue(e.target.value)}
            />
          </div>
        )}

        {currentQuery.remark_contents?.includes(
          REMARK_CONTENT_VALUES.REMARKS_OUTPUT_NHIF,
        ) && (
          <div>
            <label htmlFor={"insurance-remark"}>
              {t(
                "Remarks output when making a request to the National Health Insurance Federation",
              )}
            </label>
            <Input
              name={"remark"}
              size={"large"}
              width={200}
              id={"insurance-remark"}
              value={insuranceRemarkValue}
              onChange={(e) => setInsuranceRemarkValue(e.target.value)}
            />
          </div>
        )}

        <ActionBtns justify={"flex-end"}>
          <Button
            btnText={t("Keep")}
            shape={"round"}
            type={"primary"}
            onClick={() => {
              updateRemark(currentRecord.date, {
                remark: remarkValue,
                insuranceRemark: insuranceRemarkValue,
              })
            }}
          />
        </ActionBtns>
      </Box>
    )
  }, [])

  return {
    ServiceProvStatusEditContent,
    StartTimeEditContent,
    EndTimeEditContent,
    PastEditContent,
    ComplexEditContent,
    NumberOfHoursEditContent,
    MedicalCooperationEditContent,
    DigitalSignatureEditContent,
    TrialSupportEditContent,
    RegionalCollaborationEditContent,
    RegionalCollaborationMeetingImplementationEditContent,
    CommutingTrainingEditContent,
    TransitionPreparationEditContent,
    IntensiveSupportEditContent,
    EmergencyAcceptanceEditContent,
    RemarkEditContent,
  }
}
