import React from "react"

// packages
import { t } from "i18next"
import { Flex } from "antd"
import { useRouter } from "next/router"

// Components
import {
  Card,
  Button,
  PageTitle,
  ActionBtns,
  Breadcrumb,
  StyledColGap,
  MonthPagination,
  MAX_INTENSIVE_SUPPORT_COUNT_MONTH,
  MAX_TRIAL_USE_SUPPORT_COUNT_MONTH,
} from "@project/common"
import { ServiceProvDetailsOperation } from "./ServiceProvDetailsOperation"

// service
import { ServiceProvisionListTabs } from "./ServiceProvDetailsListTabs"

// context
import { useServiceProvisionDetail } from "../../../context/ServiceProvisionDetailContext"
import {
  ErrorAlertContainer,
  ServiceProvisionContainer,
} from "./ServiceProvDetailsInternal.styles"

// ! ## Main component
const ServiceProvDetailsInternal = () => {
  const { push } = useRouter()
  const {
    formik,
    listRef,
    breadcrumbItem,
    onPrevUser,
    hasPrevUser,
    onNextUser,
    hasNextUser,
    userName,
    hasIntensiveSupportAdditionExceeded,
    hasTrialUseSupportExceeded,
  } = useServiceProvisionDetail()

  const { setValues, values, handleSubmit } = formik

  return (
    <>
      <Breadcrumb items={breadcrumbItem} />
      <PageTitle
        icon={<img src={"../../assets/icons/data2.svg"} alt={"icon"} />}
        title={t("{{userName}}’s performance record sheet", {
          userName: userName,
        })}
        extra={
          <Flex gap={"8px 16px"} wrap={"wrap"}>
            <Button
              shape={"round"}
              btnText={`<<  ${t("Prev user")}`}
              onClick={onPrevUser}
              disabled={!hasPrevUser()}
            />
            <Button
              shape={"round"}
              btnText={`${t("Next user")}  >>`}
              onClick={onNextUser}
              disabled={!hasNextUser()}
            />
          </Flex>
        }
      />
      <StyledColGap>
        <ServiceProvDetailsOperation />

        {/* MM list starts here */}
        <ServiceProvisionContainer ref={listRef}>
          <Card
            title={t("Performance record sheet")}
            extra={
              <MonthPagination
                change={"month"}
                showDate={false}
                currentMonth={values.month - 1}
                currentYear={values.year}
                onMonthChange={(_, __, newDate) => {
                  setValues({
                    ...values,
                    year: newDate.year(),
                    month: newDate.month() + 1,
                  }).then(() => handleSubmit())
                }}
                prevText={t("Previous month")}
                currentText={t("This month")}
                nextText={t("Following month")}
              />
            }
          >
            {hasIntensiveSupportAdditionExceeded && (
              <ErrorAlertContainer>
                {t(
                  "※ Intensive support additions can only be calculated up to {{number}} times a month within 3 months.",
                  { number: MAX_INTENSIVE_SUPPORT_COUNT_MONTH },
                )}
              </ErrorAlertContainer>
            )}
            {hasTrialUseSupportExceeded && (
              <ErrorAlertContainer>
                {t(
                  "Trial use support addition can only be calculated {{number}} time per month",
                  { number: MAX_TRIAL_USE_SUPPORT_COUNT_MONTH },
                )}
              </ErrorAlertContainer>
            )}
            <Flex
              gap={"16px"}
              align={"center"}
              style={{
                marginBottom: "20px",
              }}
            >
              <ActionBtns justify={"flex-start"}>
                <Button
                  shape={"round"}
                  btnText={t("Cancel")}
                  onClick={() => push("/user-service-record")}
                />
                <Button
                  shape={"round"}
                  btnText={t("Print")}
                  type={"primary"}
                  iconType={"print"}
                  onClick={() => {
                    window.print()
                  }}
                />
              </ActionBtns>
            </Flex>
            <ServiceProvisionListTabs />
          </Card>
        </ServiceProvisionContainer>
      </StyledColGap>
    </>
  )
}

export { ServiceProvDetailsInternal }
