import { PRINT_SETTINGS_VALUES } from "@project/common/src/constants"
import { t } from "i18next"
import {
  ProvisionDataSource,
  ServiceProvDetailsOperation,
} from "../../../types/serviceRecordProvision"

export const recordSheetHeadPropsResolver =
  (eraMonth: string, currentQuery: ServiceProvDetailsOperation) =>
  (provisionDataSource: ProvisionDataSource) => {
    return {
      businessOwner: provisionDataSource?.recipientDetails?.businessOwner,
      contractAmount: provisionDataSource?.recipientDetails?.contractAmount,
      guardianAndChildName:
        provisionDataSource?.recipientDetails?.guardianAndChildName,
      officeNumber: provisionDataSource?.recipientDetails?.officeNumber,
      recipientNumber: provisionDataSource?.recipientDetails?.recipientNumber,
      eraMonth: eraMonth,
      formValue: currentQuery.print_settings.includes(
        PRINT_SETTINGS_VALUES.DISPLAY_FORM_NUMBER_VALUE,
      )
        ? `(${t("Form ") + PRINT_SETTINGS_VALUES.DISPLAY_FORM_NUMBER_VALUE})`
        : "",
      disabledChildLabel: currentQuery.print_settings?.includes(
        PRINT_SETTINGS_VALUES.CHANGE_DISABLE_CHILD_NAME_VALUE,
      )
        ? "user name"
        : "disabled user's name",
      service: provisionDataSource?.recipientDetails?.service,
    }
  }
