import Link from "next/link"
import { useRouter } from "next/router"
import React, { useCallback, useMemo } from "react"

// packages
import { t } from "i18next"
import { useFormik } from "formik"
import { useMutation, useQuery } from "react-query"
// commons
import {
  filterObject,
  useUpdateSearchParams,
  FACILITY_SERVICE_TYPES,
  scrollToSelectedElement,
  getWeekNameByDate,
  getUserAttendanceTypesByService,
  dynamicLangString,
  USER_ATTENDANCE_VALUES,
  ADDITIONAL_ITEM_VALUES,
  displayLabelForTrialUseSupportInServiceProvisionRecord,
  SERVICE_TYPE_VALUES,
  MAX_INTENSIVE_SUPPORT_COUNT_MONTH,
  MAX_INTENSIVE_SUPPORT_USE_PERIOD,
  MAX_TRIAL_USE_SUPPORT_COUNT_MONTH,
  Box,
} from "@project/common"

// services
import { getAllUsers } from "../services/user"

// utils
import { setOperationInitialFormikValues } from "../utils/setOperationInitialFormikValues"

// types
import {
  UpdatePastFn,
  UpdateEndTimeFn,
  SPDTableColumns,
  UpdateComplexFn,
  UpdateStartTimeFn,
  MedicalCooperationFn,
  UpdateNumberOfHoursFn,
  ServiceProvDetailsOperation,
  ServiceProvisionDetailsContext,
  UpdateServiceProvisionStatusFn,
  SPTableSummary,
  DigitalSignUpdateFn,
  AdditionalItemRequest,
  AdditionalItemUpdateFn,
  updateRemark,
} from "../types/serviceRecordProvision"

// constants
import { SERVICE_PROV_DETAILS_KEYS } from "../containers/ServiceProvisionRecord/constants"
import {
  getUserServiceProvisionRecordDetail,
  saveUserTransport,
} from "../services/UserProvisionRecord.services"
import {
  ACTUAL_EXPENSES_RADIO_OPTION_VALUES,
  PRINT_SETTINGS_VALUES,
  REMARK_CONTENT_VALUES,
} from "@project/common/src/constants"
import dayjs from "dayjs"

import { createOrUpdateUserDailyAttendance } from "../services"
import { notification } from "antd"
import { updateUserAdditionalItemFromServiceProvisionSheet } from "../services/userServiceProvisionRecord.services"
import { ADDITIONAL_ITEM_TYPE_VALUES } from "../constants"
const filterInitialValues: ServiceProvDetailsOperation = {
  absence_info: false,
  additional_items: [],
  display_digital_sign: false,
  add_signature_line: false,
  print_settings: [],
  remark_contents: [],
  year: null,
  month: null,
  has_actual_expenses: false,
  actual_expenses_display_setting: null,
  has_reason_for_charge: false,
  reason_for_charge_display_setting: null,
}

// ! ## Context Creation
const ServiceProvisionDetailContext = React.createContext<
  ServiceProvisionDetailsContext | undefined
>(undefined)

// ! ## Provider
const ServiceProvisionDetailProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const router = useRouter()
  const { query } = router
  const [updateParams] = useUpdateSearchParams()
  const listRef = React.useRef<HTMLDivElement>(null)
  const [userServiceProvDetailData, setUserServiceProvDetailData] =
    React.useState<SPDTableColumns[]>()
  const [userServiceProvSummary, setUserServiceProvSummary] =
    React.useState<SPTableSummary>({
      total_transport_used: "",
      total_family_addition: "",
      total_meal: "",
      total_office_consultation: "",
      total_medical_addition: "",
      total_trial_support: "",
      total_count_current_month: 0,
      total_count_cummulative: 0,
      service: 0,
    })

  // ! ## query params
  const memoizedQuery: ServiceProvDetailsOperation = React.useMemo(() => {
    const queryConst = query as Partial<
      Record<keyof ServiceProvDetailsOperation, string | string[]>
    >
    const year = +queryConst?.year || dayjs().year()
    const month = +queryConst?.month || dayjs().month()
    const print_settings =
      queryConst?.print_settings?.toString()?.split(",") || []
    const remark_contents = queryConst?.remark_contents?.toString()?.split(",")
    const has_actual_expenses =
      queryConst?.has_actual_expenses === "true" ? true : false
    const actual_expenses_display_setting =
      (queryConst?.actual_expenses_display_setting ||
        null) as ServiceProvDetailsOperation["actual_expenses_display_setting"]
    const has_reason_for_charge =
      queryConst?.has_reason_for_charge === "true" ? true : false
    const reason_for_charge_display_setting =
      (queryConst?.reason_for_charge_display_setting ||
        null) as ServiceProvDetailsOperation["reason_for_charge_display_setting"]
    const additional_items = queryConst?.additional_items
      ?.toString()
      ?.split(",")
    const absence_info = queryConst?.absence_info === "true" ? true : false
    const display_digital_sign =
      queryConst?.display_digital_sign === "true" ? true : false
    const add_signature_line =
      queryConst?.add_signature_line === "true" ? true : false

    return {
      year,
      month,
      print_settings,
      remark_contents,
      has_actual_expenses,
      actual_expenses_display_setting,
      has_reason_for_charge,
      reason_for_charge_display_setting,
      additional_items,
      absence_info,
      display_digital_sign,
      add_signature_line,
    }
  }, [query])

  // ! ## API query for fetching all users
  // 1. All users
  const { data: allUsers, isLoading: loadingAllUsers } = useQuery("all-users", {
    queryFn: getAllUsers,
    refetchOnWindowFocus: false,
    select: (data) => {
      return data?.data?.map((user) => ({
        label: user?.username,
        value: user?.id,
        id: user?.id,
        furigana: user?.furigana,
        Recipient_id_number: user?.user_certificate.number,
        service_used: t(
          FACILITY_SERVICE_TYPES[user?.user_certificate.service_type],
        ),
      }))
    },
  })
  // TODO: 2. user service record provision details API call [userQuery]
  const {
    data: serviceProvisionRecordDetail,
    isLoading: loadingServiceProvisionRecordDetail,
    refetch: refetchServiceProvisionRecordDetail,
  } = useQuery(["service-provision-record-detail", memoizedQuery], {
    queryFn: () =>
      getUserServiceProvisionRecordDetail(
        query?.us_id?.toString(),
        memoizedQuery,
      ),
    refetchOnWindowFocus: false,
    enabled: !!query.us_id,
    select: ({ data }) => {
      let dataSource = []
      const userData: any = data?.[0] || {} // api sends data in array so access 1st data

      const record_summary = {
        total_transport_used: "",
        total_family_addition: "",
        total_meal: "",
        total_office_consultation: "",
        total_medical_addition: "",
        total_trial_support: "",
        total_trial_support_count: 0,
        total_regional_collaboration: "",
        total_regional_collaboration_meeting_implementation: "",
        total_commuting_support: "",
        total_transition_preparation: "",
        total_intensive_support: "",
        total_intensive_support_count: 0,
        last_intensive_support_used_date: "",
        total_emergency_acceptance: "",
        total_special_visit: "",
        total_count_current_month: 0, // if transition support then count transition preparation else off site support
        total_count_cummulative: 0, // if transition support then cummulative_total_migration_support else cummulative_total_offsite_support
        service: userData?.user_info?.service,
      }

      const getDateDetails = (dateString: string) => {
        const date = dateString?.split("T")[0]
        const dayJsDate = dayjs(date)
        const dayOfWeek = dayJsDate.day()
        const dayOfWeekText = t(getWeekNameByDate(dayOfWeek))
        const dayOfMonth = dayJsDate.date()
        return {
          date,
          dayOfWeek,
          dayOfWeekText,
          dayOfMonth,
        }
      }
      if (
        memoizedQuery?.print_settings.includes(
          PRINT_SETTINGS_VALUES.DISPLAY_ACHIEVEMENT_IN_BLANK_PAGE_VALUE,
        )
      ) {
        // display result in blank page
        dataSource = []
      } else if (
        memoizedQuery?.print_settings.includes(
          PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE,
        )
      ) {
        // scheduled days
        let transportationUsedCount = 0
        const showTransportationData = memoizedQuery?.print_settings.includes(
          PRINT_SETTINGS_VALUES.REFLECT_DESIRED_PICK_DROP_TIMES_IN_START_END_VALUE,
        )
        dataSource = userData?.scheduled_days?.map((schedule) => {
          const dateDetails = getDateDetails(schedule?.date)
          if (showTransportationData) {
            if (schedule?.goto_meet_flag) {
              transportationUsedCount++
            }
            if (schedule?.take_flag) {
              transportationUsedCount++
            }
          }
          return {
            key: dateDetails.date,
            date: dateDetails.date,
            day: dateDetails.dayOfMonth,
            day_of_the_week: dateDetails.dayOfWeekText,
            past: {
              pickup: showTransportationData
                ? schedule?.goto_meet_flag
                  ? "1"
                  : "0"
                : "",
            },
            complex: {
              dropOff: showTransportationData
                ? schedule?.take_flag
                  ? "1"
                  : "0"
                : "",
            },
          }
        })
        record_summary.total_transport_used = `${transportationUsedCount.toString()}回`
      } else {
        // service provision

        let transportationUsedCount = 0
        const familiyAdditionCount = 0
        const officeConsultationCount = 0
        let mealCount = 0
        let medicalCount = 0
        let trailSupportCount = 0
        let regionalCollaborationCount = 0
        let regionalCollaborationMeetingImplementationCount = 0
        let commutingTrainingCount = 0
        let transitionPreparationCount = 0
        let intensiveSupportCount = 0
        let emergencyAcceptanceCount = 0
        let specialVisitCount = 0
        let currentMonthCount = 0
        let offSiteSupportCount = 0
        let lastIntensiveSupportDate = ""

        dataSource = userData?.service_provision_results?.map((result) => {
          const dateDetails = getDateDetails(result?.attendance?.date)
          const attendance = result?.attendance
          const transport = result?.transport

          const actualCostAsRemark = attendance?.user_attendance_actual_cost
            ?.filter(
              (ac) =>
                !memoizedQuery?.actual_expenses_display_setting ||
                memoizedQuery?.actual_expenses_display_setting ==
                  ACTUAL_EXPENSES_RADIO_OPTION_VALUES.INCLUDE_ACTUAL_COST_OF_0_YEN ||
                (memoizedQuery?.actual_expenses_display_setting ==
                  ACTUAL_EXPENSES_RADIO_OPTION_VALUES.DONOT_INCLUDE_ACTUAL_COST_OF_0_YEN &&
                  ac?.actual_cost_price > 0),
            )
            ?.map((ac) => ac?.actual_cost_name)
            ?.join(", ")

          const additionalItemsAsRemark =
            memoizedQuery?.additional_items?.length > 0 &&
            Array.isArray(attendance?.additional_items)
              ? attendance?.additional_items
                  ?.filter((item) =>
                    memoizedQuery.additional_items.includes(
                      item?.additional_item,
                    ),
                  )
                  .map((item, index) =>
                    item.additional_item ===
                    ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT ? (
                      <Box
                        key={item?.additional_item + index}
                        display={"flex"}
                        direction={"column"}
                        align={"center"}
                      >
                        <span>
                          {t(item?.additional_item)} {item?.start_time}
                          {"-"}
                          {item?.end_time}
                        </span>
                      </Box>
                    ) : (
                      <span key={item?.additional_item + index}>
                        {t(item?.additional_item)}
                      </span>
                    ),
                  )
              : null

          const peerSupportAdditionalItemAsRemark =
            Array.isArray(attendance?.additional_items) &&
            attendance?.additional_items?.find(
              (item) =>
                item?.additional_item == ADDITIONAL_ITEM_VALUES.PEER_SUPPORT,
            )
              ? t(ADDITIONAL_ITEM_VALUES.PEER_SUPPORT)
              : null

          const remarks = (
            <>
              {/* show remark if filter is checked */}
              {memoizedQuery?.remark_contents?.includes(
                REMARK_CONTENT_VALUES.REMARKS,
              ) ? (
                <>
                  {attendance?.remarks}
                  {attendance?.remarks && <br />}
                </>
              ) : null}
              {/* show nhif remark if filter is checked */}
              {memoizedQuery?.remark_contents?.includes(
                REMARK_CONTENT_VALUES.REMARKS_OUTPUT_NHIF,
              ) ? (
                <>
                  {attendance?.remarks_output}
                  {attendance?.remarks_output && <br />}
                </>
              ) : null}
              {/* show actual expenses if filter is checked */}
              {memoizedQuery?.has_actual_expenses ? (
                <>
                  {actualCostAsRemark} {actualCostAsRemark && <br />}
                </>
              ) : null}
              {/* show absent reason if filter is checked */}
              {memoizedQuery?.has_reason_for_charge ? (
                <>
                  {attendance?.absence_reason}
                  <br />
                </>
              ) : null}
              {/* show peer support additional item if used */}
              {peerSupportAdditionalItemAsRemark ? (
                <>
                  {peerSupportAdditionalItemAsRemark}
                  <br />
                </>
              ) : null}
              {/* show used additional items if any */}
              <Box display={"flex"} direction={"column"} align={"center"}>
                {additionalItemsAsRemark}
              </Box>
            </>
          )

          const isAbsent = [
            USER_ATTENDANCE_VALUES.ABSENCE,
            USER_ATTENDANCE_VALUES.ABSENCE_NO_ADDITION,
          ].includes(attendance?.attendance_type)

          if (!isAbsent) {
            if (transport?.pickup_users?.[0]?.use_transportation_flag) {
              transportationUsedCount++
            }
            if (transport?.drop_users?.[0]?.use_transportation_flag) {
              transportationUsedCount++
            }
          }

          if (attendance?.meal_id) {
            mealCount++
          }

          let medicalCollaborationAddition = {
            additional_item_value: null,
            additional_item_value2: null,
          }
          if (Array.isArray(attendance?.additional_items)) {
            medicalCollaborationAddition = attendance?.additional_items?.find(
              (item) =>
                item?.additional_item ==
                ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
            )
            if (medicalCollaborationAddition?.additional_item_value) {
              medicalCount++
            }
          }

          const trialSupportAddition =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.TRIAL_USE_SUPPORT,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (trialSupportAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO) {
            trailSupportCount++
          }

          const regionalCollaborationAddition =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_ADDITION,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (regionalCollaborationAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO) {
            regionalCollaborationCount++
          }

          const regionalCollaborationMeetingImplementation =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (
            regionalCollaborationMeetingImplementation !=
            ADDITIONAL_ITEM_TYPE_VALUES.NO
          ) {
            regionalCollaborationMeetingImplementationCount++
          }

          const commutingTrainingAddition =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.COMMUTING_TRAINING,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (commutingTrainingAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO) {
            commutingTrainingCount++
          }

          const transitionPreparationAddition =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.TRANSITION_PREPARATION_SUPPORT,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (transitionPreparationAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO) {
            transitionPreparationCount++
          }

          const intensiveSupportAddition =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (intensiveSupportAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO) {
            intensiveSupportCount++
            lastIntensiveSupportDate = attendance?.date
          }

          const emergenceAcceptanceAddition =
            (Array.isArray(attendance?.additional_items) &&
              attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
              )?.additional_item_value) ||
            ADDITIONAL_ITEM_TYPE_VALUES.NO

          if (emergenceAcceptanceAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO) {
            emergencyAcceptanceCount++
          }

          const specialVisitAddition = Array.isArray(
            attendance?.additional_items,
          )
            ? attendance?.additional_items?.find(
                (item) =>
                  item?.additional_item ==
                  ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
              )
            : {
                additional_item_value: ADDITIONAL_ITEM_TYPE_VALUES.NO,
                start_time: "00:00",
                end_time: "00:00",
              }

          if (
            specialVisitAddition &&
            specialVisitAddition.additional_item_value !=
              ADDITIONAL_ITEM_TYPE_VALUES.NO
          ) {
            specialVisitCount++
          }

          let offsiteSupport = ""
          if (userData?.user_info?.service == SERVICE_TYPE_VALUES.TRANSITION) {
            // if transition service count is total perparation support for month
            currentMonthCount = transitionPreparationCount
          } else {
            // else its offsite support attendance count
            if (
              attendance?.attendance_type ==
              USER_ATTENDANCE_VALUES.OFFSITE_SUPPORT
            ) {
              offsiteSupport = "1"
              offSiteSupportCount++
            }
            currentMonthCount = offSiteSupportCount
          }

          return {
            key: dateDetails.date,
            attendance,
            transport,
            user_id: attendance?.user_id,
            facility_id: attendance?.facility_id,
            date: dateDetails.date,
            day: dateDetails.dayOfMonth,
            day_of_the_week: dateDetails.dayOfWeekText,
            allowServiceProvisionEdit: true,
            attendanceOptions: getUserAttendanceTypesByService(
              userData?.user_info?.service,
            ),
            service_provision_status: t(
              getUserAttendanceTypesByService(
                userData?.user_info?.service,
              )?.find((att) => att?.value == attendance?.attendance_type)
                ?.label || "",
            ),
            service_provision_status_id: getUserAttendanceTypesByService(
              userData?.user_info?.service,
            )?.find((att) => att?.value == attendance?.attendance_type).value,
            allowStartTimeEdit: !isAbsent,
            start_time: attendance?.start_time,
            allowEndTimeEdit: !isAbsent,
            end_time: attendance?.end_time,
            allowPickupEdit: !isAbsent,
            past: !isAbsent
              ? {
                  arrival_time: transport?.pickup_users?.[0]?.arrival_time,
                  location: transport?.pickup_users?.[0]?.place,
                  location_other:
                    transport?.pickup_users?.[0]?.place_other_name,
                  pickup: transport?.pickup_users?.[0]?.use_transportation_flag
                    ? "1"
                    : "0",
                  pickup_time: transport?.pickup_users?.[0]?.pickup_time,
                }
              : {},
            allowDropEdit: !isAbsent,
            complex: !isAbsent
              ? {
                  arrival_time: transport?.drop_users?.[0]?.arrival_time,
                  location: transport?.drop_users?.[0]?.place,
                  location_other: transport?.drop_users?.[0]?.place_other_name,
                  dropOff: transport?.drop_users?.[0]?.use_transportation_flag
                    ? "1"
                    : "0",
                  dropOff_time: transport?.drop_users?.[0]?.pickup_time,
                }
              : {},
            allowSpecialVisitEdit: true,
            number_of_hours: {
              end_time: specialVisitAddition?.end_time || "00:00",
              start_time: specialVisitAddition?.start_time || "00:00",
              special_support_visit:
                specialVisitAddition?.additional_item_value ||
                ADDITIONAL_ITEM_TYPE_VALUES.NO,
            },
            meal_provision: attendance?.meal_id ? "1" : "",
            allowMedicalCooperationEdit: true,
            medical_cooperation: {
              medical_system_link:
                medicalCollaborationAddition?.additional_item_value || null,
              status:
                medicalCollaborationAddition?.additional_item_value2 || false,
            },
            allowTrialSupportEdit: true,
            trial_support_addition: trialSupportAddition,
            trial_support_addition_display_label:
              trialSupportAddition?.additional_item_value !=
              ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? displayLabelForTrialUseSupportInServiceProvisionRecord(
                    trailSupportCount,
                  )
                : "",
            total_trial_support: trailSupportCount,
            allowToEditRegionalCollaboration: true,
            regional_collaboration: regionalCollaborationAddition,
            allowToEditRegionalCollaborationMeetingImplementation: true,
            regional_collaboration_label:
              regionalCollaborationAddition != ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? "1"
                : "",
            regional_collaboration_meeting_implementation:
              regionalCollaborationMeetingImplementation,
            regional_collaboration_meeting_implementation_label:
              regionalCollaborationMeetingImplementation ==
              ADDITIONAL_ITEM_TYPE_VALUES.REGIONAL_COLLABORATION_MEETING_I
                ? "1"
                : regionalCollaborationMeetingImplementation ==
                    ADDITIONAL_ITEM_TYPE_VALUES.REGIONAL_COLLABORATION_MEETING_II
                  ? "2"
                  : "",
            total_regional_collaboration: regionalCollaborationCount,
            total_regional_collaboration_meeting_implementation:
              regionalCollaborationMeetingImplementationCount,
            allowToEditCommutingTraining: true,
            commuting_training: commutingTrainingAddition,
            total_commuting_training: commutingTrainingCount,
            allowToTransitionPreparation: true,
            transition_preparation: transitionPreparationAddition,
            total_transition_preparation: transitionPreparationCount,
            allowToEditIntensiveSupport: true,
            intensive_support: intensiveSupportAddition,
            total_intensive_support: intensiveSupportCount,
            allowToEditEmergencyAcceptance: true,
            emergency_acceptance: emergenceAcceptanceAddition,
            total_emergency_acceptance: emergencyAcceptanceCount,
            offsite_support_label: offsiteSupport,
            allowRemarkEdit: true,
            remarks,
            remarksValue: attendance?.remarks,
            remarksOutput: attendance?.remarks_output,
            allowParentSignEdit: true,
          }
        })

        record_summary.total_family_addition = `${familiyAdditionCount}回`
        record_summary.total_meal = `${mealCount}回`
        record_summary.total_medical_addition = `${medicalCount}回`
        record_summary.total_office_consultation = `${officeConsultationCount}回`
        record_summary.total_transport_used = `${transportationUsedCount}回`
        record_summary.total_trial_support = `${trailSupportCount}回`
        record_summary.total_trial_support_count = trailSupportCount
        record_summary.total_commuting_support = `${commutingTrainingCount}回`
        record_summary.total_regional_collaboration = `${regionalCollaborationCount}回`
        record_summary.total_regional_collaboration_meeting_implementation = `${regionalCollaborationMeetingImplementationCount}回`
        record_summary.total_transition_preparation = `${transitionPreparationCount}回`
        record_summary.total_intensive_support = `${intensiveSupportCount}回`
        record_summary.total_intensive_support_count = intensiveSupportCount
        record_summary.last_intensive_support_used_date =
          lastIntensiveSupportDate
        record_summary.total_emergency_acceptance = `${emergencyAcceptanceCount}回`
        record_summary.total_special_visit = `${specialVisitCount}回`
        record_summary.total_count_current_month = currentMonthCount
        record_summary.total_count_cummulative =
          userData?.user_info?.service == SERVICE_TYPE_VALUES.TRANSITION
            ? userData?.user_info?.cummulative_total_migration_support
            : userData?.user_info?.cummulative_total_offsite_support
        record_summary.service = userData?.user_info?.service
      }

      return {
        record_detail: dataSource,
        user_info: userData?.user_info,
        record_summary,
        prev_user_id: userData?.prev_user_id,
        next_user_id: userData?.next_user_id,
      }
    },

    onSuccess: (response) => {
      setUserServiceProvDetailData(response?.record_detail || [])
      setUserServiceProvSummary(response?.record_summary)
    },
  })

  // TODO: 3. mutate the user service record provision details API call [mutateUserQuery]

  // ! ## Formik form control
  const formik = useFormik<ServiceProvDetailsOperation>({
    initialValues: filterInitialValues,
    onSubmit: (values) => {
      const params = filterObject({
        ...values,
        print_settings: values.print_settings?.toString(),
        remark_contents: values.remark_contents?.toString(),
        additional_items: values.additional_items?.toString(),
        absence_info: values.absence_info?.toString(),
        display_digital_sign: values.display_digital_sign?.toString(),
        add_signature_line: values.add_signature_line?.toString(),
        tab: query.tab,
      })
      updateParams({ ...params }, `/user-service-record/details/${query.us_id}`)
      scrollToSelectedElement(listRef)
    },
  })

  const { setFieldValue, values } = formik

  // ! ## event action function
  // 1. Handle Tabs change
  const onTabChange = useCallback(
    (key: string) => {
      const params = filterObject({
        ...values,
        print_settings: values.print_settings?.toString(),
        remark_contents: values.remark_contents?.toString(),
        additional_items: values.additional_items?.toString(),
        absence_info: values.absence_info?.toString(),
        display_digital_sign: values.display_digital_sign?.toString(),
        add_signature_line: values.add_signature_line?.toString(),
        tab: key,
      })

      updateParams(
        { ...params, tab: key },
        `/user-service-record/details/${query.us_id}`,
      )
    },
    [values, query.us_id, updateParams],
  )
  // 2. Update service_provision_status
  const updateServiceProvisionStatus: UpdateServiceProvisionStatusFn =
    useCallback(
      (date, newVal) => {
        updateUserAttendance(date, {
          attendance_type: newVal.attendance_type,
          community_life_support_base:
            newVal.attendance_type.toString() ==
            USER_ATTENDANCE_VALUES.TRIAL_SUPPORT
              ? newVal.community_life_support_base
              : false,
        })
      },
      [userServiceProvDetailData],
    )

  const { mutate: updateAttendance, isLoading: updatingAttendance } =
    useMutation({
      mutationFn: createOrUpdateUserDailyAttendance,
      onSuccess: () => {
        refetchServiceProvisionRecordDetail()
        notification.success({
          message: dynamicLangString([
            "Service provision record",
            "Updated Successfully",
          ]),
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    })

  const updateUserAttendance = (date, updateObject) => {
    const data = userServiceProvDetailData?.find((item) => item.key == date)
    if (data) {
      const attendancePayload = {
        ...data.attendance,
        date,
        ...updateObject,
      }
      // prevent transportation update on attendance update from here
      delete attendancePayload.pickup
      delete attendancePayload.drop
      updateAttendance(attendancePayload)
    }
  }

  const { mutate: updateAdditionalItem, isLoading: updatingAdditionalItem } =
    useMutation({
      mutationFn: updateUserAdditionalItemFromServiceProvisionSheet,
      onSuccess: () => {
        refetchServiceProvisionRecordDetail()
        notification.success({
          message: dynamicLangString([
            "Service provision record",
            "Updated Successfully",
          ]),
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please contact administrator."),
        })
      },
    })

  const updateUserAdditionalItem = (date, updateObject) => {
    const data = userServiceProvDetailData?.find((item) => item.key == date)
    if (data) {
      const additionalItemPayload: AdditionalItemRequest = {
        facility_id: +data?.facility_id,
        date: data?.date,
        additional_items: [
          {
            user_id: data?.user_id,
            ...updateObject,
          },
        ],
      }
      updateAdditionalItem(additionalItemPayload)
    }
  }

  // 3. Update start_time
  const updateStartTime: UpdateStartTimeFn = useCallback(
    (date, newVal) => {
      updateUserAttendance(date, { start_time: `${newVal.hr}:${newVal.min}` })
    },
    [userServiceProvDetailData],
  )
  // 4. Update end_time
  const updateEndTime: UpdateEndTimeFn = useCallback(
    (date, newVal) => {
      updateUserAttendance(date, { end_time: `${newVal.hr}:${newVal.min}` })
    },
    [userServiceProvDetailData],
  )

  const { mutate: updateTransport, isLoading: updatingTransport } = useMutation(
    {
      mutationFn: saveUserTransport,
      onSuccess: () => {
        refetchServiceProvisionRecordDetail()
        notification.success({
          message: dynamicLangString([
            "Service provision record",
            "Updated Successfully",
          ]),
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("Something went wrong. Please contact administrator"),
        })
      },
    },
  )

  const updateUserTransport = (date, type, updatedTransportPayload) => {
    const data = userServiceProvDetailData?.find((item) => item.key == date)
    if (data) {
      const transportPayload: any = {}
      const userBasicData = {
        date,
        facility_id: data?.facility_id,
        user_id: data?.user_id,
      }
      if (type == "pick") {
        transportPayload.pickup_users = [
          {
            ...data.transport.pickup_users?.[0],
            ...updatedTransportPayload,
            ...userBasicData,
          },
        ]
      } else if (type == "drop") {
        transportPayload.drop_users = [
          {
            ...data.transport.drop_users?.[0],
            ...updatedTransportPayload,
            ...userBasicData,
          },
        ]
      }
      updateTransport(transportPayload)
    }
  }

  // 5. Update Past
  const updatePast: UpdatePastFn = useCallback(
    (date, newVal) => {
      const payload = {
        date,
        use_transportation_flag: +newVal?.pickup ? true : false,
        place: +newVal.location || null,
        place_other_name: newVal?.location_other,
        pickup_time: newVal?.pickup_time,
        arrival_time: newVal?.arrival_time,
      }
      updateUserTransport(date, "pick", payload)
    },
    [userServiceProvDetailData],
  )
  // 6. Update Complex
  const updateComplex: UpdateComplexFn = useCallback(
    (date, newVal) => {
      const payload = {
        date,
        use_transportation_flag: +newVal?.dropOff ? true : false,
        place: +newVal.location || null,
        place_other_name: newVal?.location_other || null,
        pickup_time: newVal?.dropOff_time,
        arrival_time: newVal?.arrival_time,
      }
      updateUserTransport(date, "drop", payload)
    },
    [userServiceProvDetailData],
  )
  // 7. Update number of hours
  const updateNumberOfHours: UpdateNumberOfHoursFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.VISIT_SPECIAL_SUPPORT,
        additional_item_value: newVal.special_support_visit,
        start_time: newVal.start_time,
        end_time: newVal.end_time,
      })
    },
    [userServiceProvDetailData],
  )

  // 9. Update medical cooperation system addition
  const updateMedicalCooperation: MedicalCooperationFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.MEDICAL_COOPERATION_SYSTEM,
        additional_item_value: newVal.medical_system_link,
        additional_item_value2: newVal.status ? true : false,
      })
    },
    [userServiceProvDetailData],
  )

  // 10. Update digital signature
  const updateDigitalSignature: DigitalSignUpdateFn = useCallback(
    (date, newVal) => {
      updateUserAttendance(date, {
        digital_signature_image: newVal.signature,
        digital_signature_date_image: newVal.date,
      })
    },
    [userServiceProvDetailData],
  )

  // 11. updateTrialSupportAddition
  const updateTrialSupportAddition: AdditionalItemUpdateFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.TRIAL_USE_SUPPORT,
        additional_item_value: newVal,
      })
    },
    [userServiceProvDetailData],
  )

  const updateRegionalCollaborationAddition: AdditionalItemUpdateFn =
    useCallback(
      (date, newVal) => {
        updateUserAdditionalItem(date, {
          additional_item:
            ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_ADDITION,
          additional_item_value: newVal,
        })
      },
      [userServiceProvDetailData],
    )

  const updateRegionalCollaborationMeetingImplementationAddition: AdditionalItemUpdateFn =
    useCallback(
      (date, newVal) => {
        updateUserAdditionalItem(date, {
          additional_item:
            ADDITIONAL_ITEM_VALUES.REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION,
          additional_item_value: newVal,
        })
      },
      [userServiceProvDetailData],
    )

  const updateCommutingTrainingAddition: AdditionalItemUpdateFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.COMMUTING_TRAINING,
        additional_item_value: newVal,
      })
    },
    [userServiceProvDetailData],
  )

  const updateTransitionPreparation: AdditionalItemUpdateFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.TRANSITION_PREPARATION_SUPPORT,
        additional_item_value: newVal,
      })
    },
    [userServiceProvDetailData],
  )

  const updateIntensiveSupportAddition: AdditionalItemUpdateFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.INTENSIVE_SUPPORT,
        additional_item_value: newVal,
      })
    },
    [userServiceProvDetailData],
  )

  const updateEmergencyAcceptance: AdditionalItemUpdateFn = useCallback(
    (date, newVal) => {
      updateUserAdditionalItem(date, {
        additional_item: ADDITIONAL_ITEM_VALUES.EMERGENCY_ACCEPTANCE,
        additional_item_value: newVal,
      })
    },
    [userServiceProvDetailData],
  )

  const updateRemark: updateRemark = useCallback(
    (date, newVal) => {
      updateUserAttendance(date, {
        remarks: newVal.remark,
        remarks_output: newVal.insuranceRemark,
      })
    },
    [userServiceProvDetailData],
  )

  const getCurrentQueriesAsArray = () => {
    const queryArray = []
    const newQuery = { ...query }
    delete newQuery.us_id
    for (const keys in newQuery) {
      queryArray.push(`${keys}=${newQuery[keys]}`)
    }
    return queryArray
  }

  // 10. Next user function
  const onNextUser = () => {
    const next_user_id = serviceProvisionRecordDetail.next_user_id
    if (next_user_id) {
      router
        .push(
          `/user-service-record/details/${next_user_id}?${getCurrentQueriesAsArray().join(
            "&",
          )}`,
        )
        .then(() => refetchServiceProvisionRecordDetail())
    }
  }

  const hasNextUser = () => !!serviceProvisionRecordDetail?.next_user_id

  // 11. Previous user function
  const onPrevUser = () => {
    const prevUserId = serviceProvisionRecordDetail.prev_user_id
    if (prevUserId) {
      router
        .push(
          `/user-service-record/details/${prevUserId}?${getCurrentQueriesAsArray().join(
            "&",
          )}`,
        )
        .then(() => refetchServiceProvisionRecordDetail())
    }
  }

  const hasPrevUser = () => !!serviceProvisionRecordDetail?.prev_user_id

  const userName = useMemo(() => {
    return allUsers?.find(
      (user) => user?.id?.toString() == query?.us_id?.toString(),
    )?.label
  }, [allUsers, router?.query?.us_id])

  // ! ## React hooks
  // 1.
  const breadcrumbItem = useMemo(
    () => [
      {
        title: <Link href={"/"}>{t("Home")}</Link>,
      },
      {
        title: t("Service provision record"),
        href: "/user-service-record",
      },
      {
        title: t("{{userName}}’s performance record sheet", {
          userName: userName,
        }),
      },
    ],
    [userName],
  )
  // 2. Set initial formik values
  React.useEffect(() => {
    setOperationInitialFormikValues(
      setFieldValue,
      memoizedQuery,
      SERVICE_PROV_DETAILS_KEYS,
    )
  }, [memoizedQuery])

  const hasIntensiveSupportAdditionExceeded: boolean = useMemo(() => {
    const firstDate =
      serviceProvisionRecordDetail?.user_info
        ?.first_intensive_support_used_date_for_fiscal_year

    // if first use date not available or is not valid then return false
    if (!firstDate || !dayjs(firstDate).isValid()) {
      return false
    }

    // if used for more than limit this month return true
    if (
      serviceProvisionRecordDetail?.record_summary
        ?.total_intensive_support_count > MAX_INTENSIVE_SUPPORT_COUNT_MONTH
    )
      return true

    const lastDate = serviceProvisionRecordDetail?.record_summary
      ?.last_intensive_support_used_date
      ? serviceProvisionRecordDetail?.record_summary?.last_intensive_support_used_date?.split(
          "T",
        )?.[0]
      : null
    // if different between first & last intensive support used date is more thanlimit
    if (lastDate && dayjs(lastDate).isValid()) {
      return (
        Math.abs(dayjs(firstDate).diff(lastDate, "days")) + 1 >
        MAX_INTENSIVE_SUPPORT_USE_PERIOD
      )
    }

    return false
  }, [serviceProvisionRecordDetail])

  const hasTrialUseSupportExceeded: boolean = useMemo(() => {
    return (
      serviceProvisionRecordDetail?.record_summary?.total_trial_support_count >
      MAX_TRIAL_USE_SUPPORT_COUNT_MONTH
    )
  }, [serviceProvisionRecordDetail])

  return (
    <ServiceProvisionDetailContext.Provider
      value={{
        formik,
        onTabChange,
        allUsers,
        loadingSheet: loadingAllUsers || loadingServiceProvisionRecordDetail,
        listRef,
        breadcrumbItem: breadcrumbItem,
        onPrevUser,
        hasPrevUser,
        onNextUser,
        hasNextUser,
        provisionDataSource: {
          recipientDetails: {
            recipientNumber:
              serviceProvisionRecordDetail?.user_info?.certificate_number
                ?.toString()
                ?.padStart(10, " "),
            guardianAndChildName: `${serviceProvisionRecordDetail?.user_info?.parent_name} ${serviceProvisionRecordDetail?.user_info?.username}`,
            officeNumber:
              serviceProvisionRecordDetail?.user_info?.business_number,
            contractAmount:
              serviceProvisionRecordDetail?.user_info?.benefit_days,
            businessOwner:
              serviceProvisionRecordDetail?.user_info?.facility_name,
            service: serviceProvisionRecordDetail?.user_info?.service,
          },
          dataSource: userServiceProvDetailData,
          footerDetails: {
            amount_paid: serviceProvisionRecordDetail?.user_info?.benefit_days,
            absence_rate: serviceProvisionRecordDetail?.user_info?.absence_rate,
            absent2_days: serviceProvisionRecordDetail?.user_info?.absent2_days,
            absent2_rate: serviceProvisionRecordDetail?.user_info?.absent2_rate,
            absent_days: serviceProvisionRecordDetail?.user_info?.absent_days,
            cancellation_days:
              serviceProvisionRecordDetail?.user_info?.cancellation_days,
            cancellation_rate:
              serviceProvisionRecordDetail?.user_info?.cancellation_rate,
            number_of_applications:
              serviceProvisionRecordDetail?.user_info?.number_of_applications,
            present_days: serviceProvisionRecordDetail?.user_info?.present_days,
            usage_rate: serviceProvisionRecordDetail?.user_info?.usage_rate,
            utilization_rate:
              serviceProvisionRecordDetail?.user_info?.utilization_rate,
            intensiveSupportAddition:
              serviceProvisionRecordDetail?.user_info
                ?.first_intensive_support_used_date_for_fiscal_year,
            initialAdditionStartDate:
              serviceProvisionRecordDetail?.user_info
                ?.initial_addition_start_date,
            initialAdditionEndDate:
              serviceProvisionRecordDetail?.user_info
                ?.initial_addition_end_date,
            calculationDaysForCurrentMonth:
              serviceProvisionRecordDetail?.user_info
                ?.calculation_days_for_current_month,
          },
          tableSummary: userServiceProvSummary,
        },
        updateServiceProvisionStatus,
        updateStartTime,
        updateEndTime,
        updatePast,
        updateComplex,
        updateNumberOfHours,
        updateMedicalCooperation,
        updateDigitalSignature,
        userName,
        updatingServiceProvision:
          updatingAttendance || updatingTransport || updatingAdditionalItem,
        currentQuery: memoizedQuery,
        updateTrialSupportAddition,
        updateRegionalCollaborationAddition,
        updateRegionalCollaborationMeetingImplementationAddition,
        updateCommutingTrainingAddition,
        updateTransitionPreparation,
        updateIntensiveSupportAddition,
        updateEmergencyAcceptance,
        updateRemark,
        hasIntensiveSupportAdditionExceeded,
        hasTrialUseSupportExceeded,
      }}
    >
      {children}
    </ServiceProvisionDetailContext.Provider>
  )
}

const useServiceProvisionDetail = () => {
  const context = React.useContext(ServiceProvisionDetailContext)
  if (!context) {
    throw new Error(
      "useServiceProvisionDetail must be used within a ServiceProvisionDetailProvider",
    )
  }
  return context
}

export { ServiceProvisionDetailProvider, useServiceProvisionDetail }
