import { API, removeBlankAttributes } from "@project/common"
import { IUserServiceProvisionRecordSearchParams } from "../types/UserServiceProvisionRecord.types"
import { AdditionalItemRequest } from "../types/serviceRecordProvision"

export const getUserServiceProvisionRecordList = (
  searchParams: IUserServiceProvisionRecordSearchParams,
) => {
  const params = removeBlankAttributes(searchParams)
  const query = []
  for (const keys in params) {
    if (Array.isArray(params[keys])) {
      query.push(`${keys}=${params[keys].join(",")}`)
    } else {
      query.push(`${keys}=${params[keys]}`)
    }
  }
  return API.get(`/user-service-provision/list?${query.join("&")}`)
}

export const updateUserAdditionalItemFromServiceProvisionSheet = (
  values: AdditionalItemRequest,
) => API.post("/additional_items", values)
