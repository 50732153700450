// packages
import { createId } from "@paralleldrive/cuid2"
import dayjs from "dayjs"
import { t } from "i18next"

// components
import { EditableCell } from "./EditableCell"

// types
import {
  SPDTableColumns,
  SPTableSummary,
  TableBodyRender,
} from "../../../types/serviceRecordProvision"
import { useMemo } from "react"
import {
  PRINT_SETTINGS_VALUES,
  REMARK_CONTENT_VALUES,
} from "@project/common/src/constants"
import { useServiceProvisionDetail } from "../../../context/ServiceProvisionDetailContext"
import { DigitalSignImage } from "./ServiceProvDetailsInternal.styles"
import {
  ADDITIONAL_ITEM_TYPE_VALUES,
  MEDICAL_COLLABORATION_SERVICE_RECORD_SHEET_DISPLAY,
} from "../../../constants"
import { SERVICE_TYPE_VALUES, USER_ATTENDANCE_VALUES } from "@project/common"

export const TBody = (restProps: any) => {
  const allData = restProps.children[1]?.map((item: any) => item?.props.record)
  // last element is the table summary
  const tableSummary = allData.pop() as SPTableSummary
  const dataSource = allData as SPDTableColumns[]

  const { currentQuery } = useServiceProvisionDetail()

  const showGuardianColumn = useMemo(() => {
    const CURRENT_PRINT_SETTINGS =
      currentQuery?.print_settings?.toString()?.split(",") || []
    return !(
      CURRENT_PRINT_SETTINGS.includes(
        PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE,
      ) &&
      !CURRENT_PRINT_SETTINGS.includes(
        PRINT_SETTINGS_VALUES.LEAVE_FIELD_FOR_GUARDIAN_CONFIRMATION_VALUE,
      )
    )
  }, [currentQuery])

  const showParentSign = useMemo(() => {
    return currentQuery?.display_digital_sign
  }, [currentQuery])

  const showParentDateSign = useMemo(() => {
    return (
      currentQuery?.display_digital_sign && currentQuery?.add_signature_line
    )
  }, [currentQuery])

  const tableDataRender: Array<TableBodyRender> = [
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (item) => {
        const startTime = dayjs(
          `2024-01-01T${item?.number_of_hours?.start_time}`,
        )
        const endTime = dayjs(`2024-01-01T${item?.number_of_hours?.end_time}`)
        let totalHours: any = endTime.diff(startTime, "hour", true)
        if (isNaN(totalHours) || !totalHours) {
          totalHours = ""
        } else {
          totalHours = Math.floor(totalHours)
        }
        return (
          <td className={"ant-table-cell align-center"}>
            {/* number of hours */}
            {item?.allowSpecialVisitEdit ? (
              <EditableCell cellToEdit={"NUMBER_OF_HOURS"} currentRecord={item}>
                {totalHours}
              </EditableCell>
            ) : null}
          </td>
        )
      },
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/* meal provision */}
          {item?.meal_provision}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/* Medical cooperation system addition [medical_cooperation]*/}

          {item?.allowMedicalCooperationEdit ? (
            <EditableCell
              cellToEdit={"MEDICAL_COOPERATION"}
              currentRecord={item}
            >
              {
                MEDICAL_COLLABORATION_SERVICE_RECORD_SHEET_DISPLAY?.[
                  item?.medical_cooperation?.medical_system_link
                ]
              }
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TRANSITION],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/* Commuting training addition */}
          {item?.allowToEditCommutingTraining ? (
            <EditableCell
              cellToEdit={"COMMUTING_TRAINING"}
              currentRecord={item}
            >
              {item?.commuting_training &&
              item?.commuting_training != ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? "1"
                : ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/* Trial use support addition*/}
          {item?.allowTrialSupportEdit ? (
            <EditableCell
              cellToEdit={"TRIAL_SUPPORT_ADDITION"}
              currentRecord={item}
            >
              {item?.trial_support_addition != ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? item?.trial_support_addition_display_label
                : ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TYPE_2],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/*Regional collaboration */}
          {item?.allowToEditRegionalCollaboration ? (
            <EditableCell
              cellToEdit={"REGIONAL_COLLABORATION_ADDITION"}
              currentRecord={item}
            >
              {item?.regional_collaboration_label || ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TRANSITION],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/*Regional collaboration meeting implementation */}
          {item?.allowToEditRegionalCollaborationMeetingImplementation ? (
            <EditableCell
              cellToEdit={"REGIONAL_COLLABORATION_MEETING_IMPLEMENTATION"}
              currentRecord={item}
            >
              {item?.regional_collaboration_meeting_implementation_label || ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/*Emergency acceptance addition */}
          {item?.allowToEditEmergencyAcceptance ? (
            <EditableCell
              cellToEdit={"EMERGENCY_ACCEPTANCE_ADDITION"}
              currentRecord={item}
            >
              {item?.emergency_acceptance != ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? "1"
                : ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/*intensive support addition */}
          {item?.allowToEditIntensiveSupport ? (
            <EditableCell
              cellToEdit={"INTENSIVE_SUPPORT_ADDITION"}
              currentRecord={item}
            >
              {item?.intensive_support != ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? "1"
                : ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
      ],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {/* Off-site support [off_site_support]*/}
          {item?.offsite_support_label}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TRANSITION],
      render: (item) => (
        <td className={"ant-table-cell align-center"}>
          {item?.allowToTransitionPreparation ? (
            <EditableCell
              cellToEdit={"TRANSITION_PREPARATION_ADDITION"}
              currentRecord={item}
            >
              {item?.transition_preparation != ADDITIONAL_ITEM_TYPE_VALUES.NO
                ? "1"
                : ""}
            </EditableCell>
          ) : null}
        </td>
      ),
    },
  ]

  const tableSummaryRender: Array<TableBodyRender> = [
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {tableSummary?.total_special_visit}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {tableSummary?.total_meal}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {tableSummary?.total_medical_addition}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TRANSITION],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {/* commuting trainging */}
          {tableSummary?.total_commuting_support}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {tableSummary?.total_trial_support}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TYPE_2],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {/* regional collaboration */}
          {tableSummary?.total_regional_collaboration}
        </td>
      ),
    },
    {
      supportedServices: [SERVICE_TYPE_VALUES.TRANSITION],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {/* regional collaboration meeting implementation */}
          {tableSummary?.total_regional_collaboration_meeting_implementation}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {/* Emergency acceptance addition */}
          {tableSummary?.total_emergency_acceptance}
        </td>
      ),
    },
    {
      supportedServices: [
        SERVICE_TYPE_VALUES.TYPE_1,
        SERVICE_TYPE_VALUES.TYPE_2,
        SERVICE_TYPE_VALUES.TRANSITION,
      ],
      render: (tableSummary) => (
        <td className={"ant-table-cell align-center"} rowSpan={2}>
          {/* Intensive support addition */}
          {tableSummary?.total_intensive_support}
        </td>
      ),
    },
  ]

  return (
    <>
      <tbody className={"ant-table-tbody"} {...restProps}>
        {dataSource.map((item, i) => {
          return (
            <tr
              key={createId() + item?.key}
              className={`ant-table-row ant-table-row-level-0 ${
                !item?.key ? "empty-row" : null
              }`}
              data-row-key={i}
            >
              {/* day */}
              <td className={"ant-table-cell"} style={{ textAlign: "center" }}>
                {item?.key ? (
                  item?.day
                ) : (
                  <span className={"insert-space"}>&nbsp;</span>
                )}
              </td>
              {/* day of the week */}
              <td className={"ant-table-cell"} style={{ textAlign: "center" }}>
                {item?.day_of_the_week}
              </td>
              {/* service provision status */}
              <td className={"ant-table-cell"}>
                {item?.allowServiceProvisionEdit ? (
                  <EditableCell
                    cellToEdit={"SERVICE_PROVISION"}
                    currentRecord={item}
                  >
                    {item?.service_provision_status_id !==
                    USER_ATTENDANCE_VALUES.ATTENDANCE
                      ? item?.service_provision_status
                      : ""}
                  </EditableCell>
                ) : item?.service_provision_status_id !==
                  USER_ATTENDANCE_VALUES.ATTENDANCE ? (
                  item?.service_provision_status
                ) : (
                  ""
                )}
              </td>
              {/* start time */}
              <td className={"ant-table-cell time-cell"}>
                {item?.allowStartTimeEdit ? (
                  <EditableCell cellToEdit={"START_TIME"} currentRecord={item}>
                    {item?.start_time}
                  </EditableCell>
                ) : (
                  item?.start_time
                )}
              </td>
              {/* end time */}
              <td className={"ant-table-cell time-cell"}>
                {item?.allowEndTimeEdit ? (
                  <EditableCell cellToEdit={"END_TIME"} currentRecord={item}>
                    {item?.end_time}
                  </EditableCell>
                ) : (
                  item?.end_time
                )}
              </td>
              {/* past */}
              <td className={"ant-table-cell past-cell align-center"}>
                {item?.allowPickupEdit ? (
                  <EditableCell cellToEdit={"PAST"} currentRecord={item}>
                    {item?.past?.pickup === "1" ? "1" : ""}
                  </EditableCell>
                ) : (
                  item?.past?.pickup
                )}
              </td>
              {/* complex */}
              <td className={"ant-table-cell complex-cell align-center"}>
                {item?.allowDropEdit ? (
                  <EditableCell cellToEdit={"COMPLEX"} currentRecord={item}>
                    {item?.complex?.dropOff === "1" ? "1" : ""}
                  </EditableCell>
                ) : (
                  item?.complex?.dropOff
                )}
              </td>

              {tableDataRender
                .filter((tr) =>
                  tr.supportedServices.includes(tableSummary?.service),
                )
                .map((tr) => tr.render(item))}

              {/* Confirmation columns for guardians, etc */}
              {showGuardianColumn && (
                <td className={"ant-table-cell align-center"}>
                  {showParentSign && item?.allowParentSignEdit ? (
                    <EditableCell
                      cellToEdit={"DIGITAL_SIGNATURE"}
                      currentRecord={item}
                      digitalSignType={
                        showParentSign && showParentDateSign ? "both" : "name"
                      }
                    >
                      {showParentDateSign &&
                        item?.attendance?.digital_signature_date_image && (
                          <DigitalSignImage
                            src={item?.attendance?.digital_signature_date_image}
                          />
                        )}
                      {item?.attendance?.digital_signature_image && (
                        <DigitalSignImage
                          src={item?.attendance?.digital_signature_image}
                        />
                      )}
                    </EditableCell>
                  ) : (
                    <div>
                      {item?.attendance?.digital_signature_image && (
                        <DigitalSignImage
                          src={item?.attendance?.digital_signature_image}
                        />
                      )}
                    </div>
                  )}
                </td>
              )}

              {/* remarks */}
              <td className={"ant-table-cell align-center"}>
                {!item?.allowRemarkEdit ? (
                  ""
                ) : currentQuery?.remark_contents?.includes(
                    REMARK_CONTENT_VALUES.REMARKS,
                  ) ||
                  currentQuery?.remark_contents?.includes(
                    REMARK_CONTENT_VALUES.REMARKS_OUTPUT_NHIF,
                  ) ? (
                  <EditableCell cellToEdit={"REMARK"} currentRecord={item}>
                    {item?.remarks}
                  </EditableCell>
                ) : (
                  item?.remarks
                )}
              </td>
            </tr>
          )
        })}
      </tbody>

      {/* Footer Total amount  */}
      <tbody className={"ant-table-tbody footer-total-tbody"}>
        <tr className={"total ant-table-row ant-table-row-level-1"}>
          <th
            className={"ant-table-cell total-head"}
            colSpan={restProps?.oneCellDown ? 4 : 5}
            rowSpan={2}
          >
            {t("Total")}
          </th>
          <td className={"ant-table-cell"} colSpan={2} rowSpan={2}>
            {tableSummary?.total_transport_used}
          </td>

          {tableSummaryRender
            ?.filter((tr) =>
              tr.supportedServices.includes(tableSummary?.service),
            )
            ?.map((tr) => tr.render(tableSummary))}

          <td colSpan={1} className={"noData ant-table-cell"} rowSpan={2}>
            <span style={{ whiteSpace: "pre-wrap" }}>
              {tableSummary?.service == SERVICE_TYPE_VALUES.TRANSITION ? (
                <span style={{ whiteSpace: "pre-wrap" }}>
                  {t("Preparing for migration\nSupport system\naddition")}
                </span>
              ) : (
                t("Off-facility support")
              )}
            </span>
          </td>

          {/* multiple-cell || summary-cell starts */}
          <th
            colSpan={1}
            className={
              "summary-cell dashed-border bottom-dashed ant-table-cell"
            }
          >
            {"当月"}
          </th>
          <td
            colSpan={1}
            className={
              "summary-cell dashed-border bottom-dashed ant-table-cell"
            }
          >
            {`${tableSummary?.total_count_current_month}日`}
          </td>
        </tr>

        <tr className={"total ant-table-row ant-table-row-level-1"}>
          <th
            colSpan={1}
            className={"summary-cell dashed-border top-dashed ant-table-cell"}
          >
            {"累計"}
          </th>
          <td
            colSpan={1}
            className={"summary-cell dashed-border top-dashed ant-table-cell"}
          >
            {`${tableSummary?.total_count_cummulative}日/180日`}
          </td>
        </tr>
        {/* multiple-cell || summary-cell ends */}
      </tbody>
    </>
  )
}
