import React, { useContext } from "react"

// packages
import { Flex } from "antd"
import { t } from "i18next"

// Components
import {
  Box,
  Button,
  CheckBox,
  ActionBtns,
  RadioGroup,
  AccordionCard,
  CheckBoxGroup,
  DatePicker,
  SERVICE_TYPE_VALUES,
} from "@project/common"
import { OperationFields } from "../common/OperationFields"

// styles
import { ServiceProvDetailsOperationContent } from "./ServiceProvDetailsInternal.styles"

// context
import { useServiceProvisionDetail } from "../../../context/ServiceProvisionDetailContext"

// constants
import {
  PRINT_SETTINGS,
  TRANSITION_TYPE_ADDITIONAL_ITEM,
  AB_TYPE_ADDITIONAL_ITEM,
  SERVICE_PROVISION_REMARK_CONTENT,
  ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED,
  ADDITIONAL_REMARK_CONTENT_WHEN_REASON_FOR_EXTRA_CHARGE_IS_CHECKED,
  PRINT_SETTINGS_VALUES,
  ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED,
  PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED,
} from "@project/common/src/constants"
import dayjs from "dayjs"
import { AuthContext } from "../../../context"

export const ServiceProvDetailsOperation = () => {
  const { formik, currentQuery } = useServiceProvisionDetail()

  const { queryClient } = useContext(AuthContext)

  const getOptionsWithTranslatedLabel = (
    options: Array<{ label: string; value: any }>,
  ) => {
    return options.map((option) => {
      option.label = t(option.label)
      return option
    })
  }

  const serviceProvDetails = queryClient.getQueryData([
    "service-provision-record-detail",
    currentQuery,
  ])
  /**
   * serviceType = 1 | 2 | 3
   */
  const serviceType = serviceProvDetails?.data[0]?.user_info?.service

  const { values, setFieldValue, handleChange, handleSubmit } = formik

  const getPrintSettingOptions = () => {
    const options = []

    PRINT_SETTINGS?.forEach((setting) => {
      setting.label = t(setting.label)
      options.push(setting)
      if (
        setting.value ==
        PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE
      ) {
        if (
          values?.print_settings?.includes(
            PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE,
          )
        ) {
          options.push(
            ...ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED,
          )
        }
      } else if (
        setting.value == PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE
      ) {
        if (
          values?.print_settings?.includes(
            PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE,
          )
        )
          options.push(...PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED)
      }
    })

    return options
  }

  return (
    <AccordionCard
      defaultActiveKey={["service-provision-record-details"]}
      items={[
        {
          key: "service-provision-record-details",
          label: t("Operation Options"),
          children: (
            <ServiceProvDetailsOperationContent>
              <OperationFields
                label={"Year Month"}
                labelWidth={"160px"}
                wrap={"wrap"}
                gap={"10px 24px"}
              >
                <Flex gap={"16px"} className={"operation_inputs"}>
                  <Flex gap={8} align={"center"}>
                    <DatePicker
                      date={
                        dayjs(
                          `${formik?.values?.year}-${formik?.values?.month}`,
                        ).isValid()
                          ? dayjs(
                              `${formik?.values?.year}-${formik?.values?.month}`,
                            )
                          : null
                      }
                      onDateChange={(date) => {
                        formik.setFieldValue("year", date.year())
                        formik.setFieldValue("month", date.month() + 1)
                      }}
                      picker={"month"}
                      format={"YYYY年MM月"}
                    />{" "}
                  </Flex>
                </Flex>
              </OperationFields>

              <OperationFields
                label={"Print settings"}
                align={"flex-start"}
                labelWidth={"160px"}
                gap={"10px 24px"}
                wrap={"wrap"}
              >
                <div className={"operation_inputs"}>
                  <CheckBoxGroup
                    items={getPrintSettingOptions()}
                    name={"print_settings"}
                    onChange={(val) => {
                      setFieldValue("print_settings", val)
                    }}
                    value={values.print_settings}
                  />
                </div>
              </OperationFields>

              <OperationFields
                label={"Remarks contents"}
                align={"flex-start"}
                gap={"10px 24px"}
                labelWidth={"160px"}
                wrap={"wrap"}
              >
                <div className={"operation_inputs"}>
                  <Box display={"flex"} gap={"xs"} wrap={"wrap"}>
                    <CheckBoxGroup
                      items={getOptionsWithTranslatedLabel(
                        SERVICE_PROVISION_REMARK_CONTENT,
                      )}
                      name={"remark_contents"}
                      onChange={(val) => {
                        setFieldValue("remark_contents", val)
                      }}
                      value={values.remark_contents}
                    />
                    <CheckBox
                      label={t("Actual expenses/expenses")}
                      name={"has_actual_expenses"}
                      value={values.has_actual_expenses}
                      onChange={(e) => {
                        handleChange(e)
                        setFieldValue("actual_expenses_display_setting", "")
                      }}
                      checked={values.has_actual_expenses}
                    />
                    {values.has_actual_expenses && (
                      <Flex>
                        <RadioGroup
                          options={
                            ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED
                          }
                          name={"actual_expenses_display_setting"}
                          value={values.actual_expenses_display_setting}
                          onChange={(e) => {
                            setFieldValue(
                              "actual_expenses_display_setting",
                              e.target.value,
                            )
                          }}
                        />
                      </Flex>
                    )}
                    <CheckBox
                      label={t("Reasons for extra charge for absenteeism")}
                      name={"has_reason_for_charge"}
                      value={values.has_reason_for_charge}
                      onChange={(e) => {
                        handleChange(e)
                        setFieldValue("reason_for_charge_display_setting", "")
                      }}
                      checked={values.has_reason_for_charge}
                    />
                    {values.has_reason_for_charge && values.absence_info && (
                      <Flex>
                        <RadioGroup
                          options={
                            ADDITIONAL_REMARK_CONTENT_WHEN_REASON_FOR_EXTRA_CHARGE_IS_CHECKED
                          }
                          name={"reason_for_charge_display_setting"}
                          value={values.reason_for_charge_display_setting}
                          onChange={(e) => {
                            setFieldValue(
                              "reason_for_charge_display_setting",
                              e.target.value,
                            )
                          }}
                        />
                      </Flex>
                    )}
                  </Box>
                </div>
              </OperationFields>

              <OperationFields
                align={"flex-start"}
                label={"Additional items"}
                gap={"10px 24px"}
                wrap={"wrap"}
                labelWidth={"160px"}
              >
                <div className={"operation_inputs"}>
                  <CheckBoxGroup
                    items={getOptionsWithTranslatedLabel(
                      serviceType === SERVICE_TYPE_VALUES.TRANSITION
                        ? TRANSITION_TYPE_ADDITIONAL_ITEM
                        : AB_TYPE_ADDITIONAL_ITEM,
                    )}
                    name={"additional_items"}
                    onChange={(val) => {
                      setFieldValue("additional_items", val)
                    }}
                    value={values.additional_items}
                  />
                </div>
              </OperationFields>

              <OperationFields
                align={"flex-start"}
                label={"Absence information"}
                gap={"10px 24px"}
                wrap={"wrap"}
                labelWidth={"160px"}
              >
                <div className={"operation_inputs"}>
                  <CheckBox
                    label={t(
                      "Absence (No addition)、Display information that does not calculate Absence Support Addition II",
                    )}
                    name={"absence_info"}
                    onChange={(e) => {
                      handleChange(e)
                      setFieldValue("reason_for_charge_display_setting", "")
                    }}
                    value={values.absence_info}
                    checked={values.absence_info}
                  />
                </div>
              </OperationFields>

              <OperationFields
                align={"flex-start"}
                label={"Digital sign settings"}
                gap={"10px 24px"}
                labelWidth={"160px"}
                wrap={"wrap"}
              >
                <div className={"operation_inputs"}>
                  <Flex>
                    <CheckBox
                      label={t("Sign and display with a digital signature")}
                      name={"display_digital_sign"}
                      value={values.display_digital_sign}
                      checked={values.display_digital_sign}
                      onChange={(e) => {
                        handleChange(e)
                        if (e.target.checked === false) {
                          setFieldValue("add_signature_line", false)
                        }
                      }}
                    />
                    {values.display_digital_sign && (
                      <CheckBox
                        label={t(
                          "Enter and display the date in the signature field",
                        )}
                        name={"add_signature_line"}
                        value={values.add_signature_line}
                        checked={values.add_signature_line}
                        onChange={handleChange}
                      />
                    )}
                  </Flex>
                </div>
              </OperationFields>

              <ActionBtns justify={"flex-start"}>
                <Button
                  shape={"round"}
                  btnText={t("Display Change")}
                  onClick={() => handleSubmit()}
                />
              </ActionBtns>
            </ServiceProvDetailsOperationContent>
          ),
        },
      ]}
    />
  )
}
