import {
  ProvisionDataSource,
  ServiceProvDetailsOperation,
} from "../../../types/serviceRecordProvision"
import { PRINT_SETTINGS_VALUES } from "@project/common/src/constants"

export const footerPropsResolver =
  (currentQuery: ServiceProvDetailsOperation, username: string) =>
  (item: ProvisionDataSource) => {
    return {
      amountPaid: item?.footerDetails?.amount_paid,
      numberOfApplications: item?.footerDetails?.number_of_applications,
      daysUsed: item?.footerDetails?.present_days,
      daysAbsent: item?.footerDetails?.absent_days,
      daysCancelled: item?.footerDetails?.cancellation_days,
      payoutRate: item?.footerDetails?.usage_rate,
      utilizationRate: item?.footerDetails?.utilization_rate,
      absenceRate: item?.footerDetails?.absence_rate,
      cancellationRate: item?.footerDetails?.cancellation_rate,
      showConfirmationContent: currentQuery.print_settings?.includes(
        PRINT_SETTINGS_VALUES.CONFIRMED_CONTENT_VALUE,
      ),
      showColumnTable: currentQuery.print_settings?.includes(
        PRINT_SETTINGS_VALUES.DISPLAY_HOW_MANY_COLUMN_VALUE,
      ),
      showParentConfirmationAtBottom: currentQuery.print_settings?.includes(
        PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE,
      ),
      showDateAtParentConfirmation: currentQuery?.print_settings?.includes(
        PRINT_SETTINGS_VALUES.SHOW_DATE_FIELD_BOTTOM_VALUE,
      ),
      showUserNameAtParentConfirmation: currentQuery?.print_settings?.includes(
        PRINT_SETTINGS_VALUES.DISPLAY_CHILD_NAME_AT_BOTTOM_VALUE,
      ),
      userName: username,
      showParentSignatureAtParentConfirmation:
        currentQuery?.print_settings?.includes(
          PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE,
        ),
      showSealAtParentConfirmation: currentQuery?.print_settings?.includes(
        PRINT_SETTINGS_VALUES?.DISPLAY_SEAL_COLUMN_AT_BOTTOM_VALUE,
      ),
      intensiveSupportAddition: item?.footerDetails?.intensiveSupportAddition,
      initialAdditionStartDate: item?.footerDetails?.initialAdditionStartDate,
      initialAdditionEndDate: item?.footerDetails?.initialAdditionEndDate,
      calculationDaysForCurrentMonth:
        item?.footerDetails?.calculationDaysForCurrentMonth,
      userService: item?.recipientDetails?.service,
    }
  }
