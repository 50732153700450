import { SPDTableColumns } from "../../../types/serviceRecordProvision"

export const serviceProvDetailsDataResolver = (
  data: SPDTableColumns[],
): SPDTableColumns[] => {
  const actualDataLength = data?.length
  // creating empty rows 24 - actualDataLength meaning if actualDataLength is 10 then 24 - 10 = 14 empty rows
  const emptyRows = Array.from({ length: 24 - actualDataLength }).map(() => ({
    key: "",
    id: "",
    user_id: "",
    facility_id: "",
    day: "",
    date: "",
    day_of_the_week: "",
    service_provision_status: null,
    start_time: "",
    end_time: "",
    past: null,
    complex: null,
    number_of_hours: null,
    meal_provision: "",
    medical_cooperation: null,
    guardian_confirmation: "",
    remarks: "",
  }))

  return [...data, ...emptyRows]
}
