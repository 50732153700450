import { API } from "@project/common"

import {
  ServiceProvDetailsOperation,
  ServiceProvOperation,
} from "../types/serviceRecordProvision"
import { UserDailyTransport } from "./user-transport-mgmt.services"

// user-service-provision/detail
/** USProvisionDetailData as in User service provision Detail data */
export interface USProvisionDetailData {
  data: USProvisionDetail[]
}

export interface USProvisionDetail {
  user_info: UserInfo
  service_provision_results: ServiceProvisionResult[]
  scheduled_days: ScheduledDay[]
}

export interface UserInfo {
  user_id: number
  username: string
  parent_name: string
  certificate_number: string
  service: number
  benefit_days: string
  business_number: string
  facility_name: string
  number_of_applications: number
  present_days: number
  absent_days: number
  absent2_days: number
  cancellation_days: number
  usage_rate: string
  utilization_rate: string
  absence_rate: string
  absent2_rate: string
  cancellation_rate: string
  first_intensive_support_used_date_for_fiscal_year: string
  initial_addition_start_date: string
  initial_addition_end_date: string
  calculation_days_for_current_month: number
  cummulative_total_migration_support: number
  cummulative_total_offsite_support: number
}

export interface ServiceProvisionResult {
  attendance: Attendance
  transport: UserDailyTransport
}

export interface Attendance {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  facility_id: number
  user_id: number
  user: User
  date: string
  save_staff_id: any
  attendance_type: string
  absence_reason: any
  service_type: number
  start_time: string
  end_time: string
  remarks: any
  remarks_output: any
  temperature: any
  meal_id: any
  digital_signature_image: any
  digital_signature_date_image: any
  medical_collaboration_system?: number
  guidance_flag?: number
  others: any
  goto_meet_flag: number
  goto_meet_time: string
  take_flag: number
  take_time: string
  user_attendance_actual_cost: any
  scheduled_meal_id: number
  scheduled_meal: ScheduledMeal
  absent_count_for_month: number
  facility_name: string
  staff: Staff
  additional_items?: Array<AdditionalItems>
}

export interface User {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  company: Company
  username: string
  furigana: string
  dob: string
  gender: string
  phone: string
  pobox: string
  pref_id: number
  address: string
  wage_style: string
  hourly_wage: number
  parent_id: any
  image: string
  financial_institution_name_kana: string
  branch_name_kana: string
  financial_institute_code: string
  branch_code: any
  account_type: any
  account_number: any
  account_holder_name: string
  customer_code: any
  email_address: string
  email_address_2: string
  attend_flag: boolean
  leave_flag: boolean
  emergency_contact_name1: string
  emergency_contact_phone1: string
  emergency_contact_name2: string
  emergency_contact_phone2: string
  emergency_contact_remarks: string
  contract_doc: string
  hospital_name: string
  doctor_name: string
  hospital_ph: string
  allergy_flag: boolean
  allergy_remarks: string
  symptoms: string
  medical_info: string
  other_service: string
  note_book_grade_flag: boolean
  note_book_grade?: string
  family_structure: string
  disability_basic_pension: boolean
  disability_grade: number
  disability_grade_text?: string
  remarks: string
  things_of_interest: string
  thing_to_watchout: string
  email: string
  user_certificate: UserCertificate
  active_contract: any
  user_history: any
}

export interface Company {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_name: string
  company_type: string
  day_service_name: string
  representative_director_name: string
  position_name: string
  pref_id: number
  Pref: any
  zip_code: string
  Zip: Zip
  address: string
  address2: string
  tel_no: string
  TelNo_ShowMyPage: boolean
  fax_no: string
  FaxNo_ShowMyPage: boolean
  mail_address: string
  reception_info: string
  memo: string
  account_status: string
  last_logged_in: string
  Facilities: any
}

export interface Zip {
  zip_code: string
  company_id: number
  perf_name: string
  city_name: string
  town_name: string
}

export interface UserCertificate {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  user_id: number
  start_date: string
  certificate_original: string
  number: string
  provision_city_id: any
  provision_city: ProvisionCity
  service_type: number
  physical_disability_flag: any
  intellectual_disability_flag: any
  mental_disability_flag: any
  development_disability_flag: any
  brain_disfunction_flag: any
  disabled_child_support_flag: any
  incurable_disease_flag: any
  benefit_payment_amount: any
  application_period_input_flag: any
  benefit_start_date: any
  benefit_end_date: any
  provision_start_date: any
  provision_end_date: any
  consultation_support_office_id: any
  consultation_support_office: ConsultationSupportOffice
  consultation_support_specialist: any
  burden_upper_limit_monthly_amount: any
  applicable_start_date: any
  applicable_end_date: any
  contract_and_certificate: any
}

export interface ProvisionCity {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  provision_city_name: string
  municipality_number: string
  subsidy_municipality_number: string
  mayor_name: string
  zip_code: string
  address: string
  pref_id: number
  prefecture_name: string
  prefecture: string
  phone: string
  email: string
}

export interface ConsultationSupportOffice {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  consultation_support_name: string
  consultation_support_name_hiragana: string
  consultation_support_number: string
  post_box_number: string
  address: string
  phone_number: string
  fax_number: string
  pref_id: number
}

export interface ScheduledMeal {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  name: string
  cost: number
}

export interface Staff {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  staff_name: string
  staff_name_furiganaame: string
  joining_date: any
  retirement_date: any
  staff_qualification: any
  staff_experience: any
  staff_facility: any
}

export interface AdditionalItems {
  additional_item: string
  additional_item_value: string
  additional_item_value2: number
  start_time: string
  end_time: string
}

export interface ScheduledDay {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  facility_id: number
  user_id: number
  date: string
  shift_id: any
  meal_flag: any
  meal_id: any
  goto_meet_flag: number
  goto_meet_place: number
  goto_meet_place_other: any
  goto_meet_time: string
  take_flag: number
  take_place: number
  take_place_other?: string
  take_time: string
  remarks: any
  is_applied: number
  facility: Facility
  attendance_shift: AttendanceShift
  meal: Meal
  holiday: string
}

export interface Facility {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  business_number: string
  facility_name: string
  facility_name_furigana: string
  facility_type: number
  grade: string
  physical_disability_support_flag: boolean
  intellectual_disability_support_flag: boolean
  mental_disability_support_flag: boolean
  development_disability_support_flag: boolean
  brain_disfunction_support_flag: boolean
  disabled_child_support_flag: boolean
  incurable_disease_support_flag: boolean
  meal_provision_flag: boolean
  capacity: number
  support_visits_nurseries_flag: boolean
  mail_address: string
  show_my_page_info: boolean
  contact_po_box: string
  address: string
  contact_phone: string
  fax: string
  bcp_evacuation: string
  bcp_pref_id: number
  remaining_address: string
  bcp_phone: string
  hospital_name: string
  hospital_po_box: string
  hospital_pref_id: number
  municipality_address: string
  hospital_phone: string
  note: string
  pay_slip_alert_date: string
  facility_wage_setting: any
  corporate_name: string
  representative_name: string
  administrator_name: string
  invoice_title: string
  invoice_full_name: string
  financial_institution_name: string
  financial_institution_branch_name: any
  financial_institution_name_kana: string
  financial_institution_branch_name_kana: string
  account_type: number
  account_holder_name: string
  account_holder_name_kana: string
  consign_number: string
  consign_name_kana: string
  branch_no: string
  bank_no: string
  is_draft: boolean
}

export interface AttendanceShift {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  attendance_shift_name: string
  attendance_shift_display_name: string
  number_of_employees_include_flg: number
  use_rest_time_flg: number
  rest_time_minutes: number
  attendance_start_time: string
  attendance_end_time: string
  attendance_rest_minits_2: number
  attendance_rest_minits: number
  attendance_start_time_2: string
  attendance_end_time_2: string
  remarks: string
  target_of_use_staff: number
  target_of_use_user: number
  attendance_shift_facilities: any
}

export interface Meal {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  name: string
  cost: number
}

export const getUserServiceProvisionRecordList = (
  params: ServiceProvOperation,
) => {
  const query = []
  for (const keys in params) {
    if (Array.isArray(params[keys])) {
      if (params[keys].length > 0)
        query.push(`${keys}=${params[keys].join(",")}`)
    } else if (params[keys]) {
      query.push(`${keys}=${params[keys]}`)
    }
  }
  return API.get(`/service-provision-record/list?${query.join("&")}`)
}

export const getUserServiceProvisionRecordDetail = (
  userIds: string | number,
  params: ServiceProvDetailsOperation,
): Promise<any> => {
  const query = [`user_ids=${userIds}`]

  if (params?.year) {
    query.push(`year=${params.year}`)
  }

  if (params?.month) {
    query.push(`month=${params.month}`)
  }

  if (params?.has_actual_expenses) {
    query.push(`show_actual_expenses=1`)
  }

  if (params?.additional_items?.length > 0) {
    // not used currently
    query.push(`additional_items=${params.additional_items.join(",")}`)
  }

  if (params.absence_info) {
    query.push(`show_no_addition_absence=1`)
  }

  if (params.page_size) {
    query.push(`pageSize=${params.page_size}`)
  }

  return API.get(`/user-service-provision/detail?${query.join("&")}`)
}

export const saveUserTransport = (payload) =>
  API.post("/transportation-users", payload)

export const getIncompleteUserTransport = (query: string) =>
  API.get(`/transportation-users/incomplete-transports?${query}`)
