import { useRouter } from "next/router"
import { useMemo } from "react"

// packages
import { Spin, TabsProps } from "antd"
import { t } from "i18next"
import wareki from "wareki"

// Components
import {
  Box,
  PrintFriendlyTable,
  SERVICE_TYPE_VALUES,
  Table,
  Tabs,
} from "@project/common"
import { TBody } from "./TBody"
import { TFooter } from "./TFooter"

// styles
import {
  RecordListSheetWrapper,
  ServiceRecordTabContainer,
  TableUpperSection,
} from "./ServiceProvDetailsInternal.styles"

// context
import { useServiceProvisionDetail } from "../../../context/ServiceProvisionDetailContext"

// utils
import { eliminateParentCol } from "../utils/eliminateParentCol"
import { footerPropsResolver } from "../utils/footerPropsResolver"
import { recordSheetHeadPropsResolver } from "../utils/recordSheetHeadPropsResolver"
import { serviceProvDetailsDataResolver } from "../utils/serviceProvDetailsDataResolver"

// constants
import { PRINT_SETTINGS_VALUES } from "@project/common/src/constants"
import { styled } from "styled-components"
import { ServiceProvDetailsOperation } from "../../../types/serviceRecordProvision"
import {
  SPD_COLUMNS_SERVICE_A,
  SPD_COLUMNS_SERVICE_B,
  SPD_COLUMNS_SERVICE_TRANSITION,
} from "./ServiceProvDetailsListColumn"

const DateEra = styled.div`
  position: absolute;
  left: 0;
  @media screen and (max-width: 675px) {
    position: relative;
  }
  @media print {
    left: 0 !important;
  }
`

const StyledBox = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 675px) {
    align-items: flex-start;
    column-gap: 2rem;
    flex-direction: column;
  }
`
const FormNumber = styled.div`
  position: absolute;
  right: 0;
  font-size: 0.75rem;
  @media screen and (max-width: 675px) {
    position: relative;
  }
`

// ! ## Main component
export const ServiceProvisionListTabs = () => {
  const { query } = useRouter()
  const userId = query?.us_id?.toString()

  const {
    userName,
    onTabChange,
    loadingSheet,
    currentQuery,
    provisionDataSource,
    updatingServiceProvision,
  } = useServiceProvisionDetail()
  const ORIGINAL_SPD_COLUMNS =
    provisionDataSource?.recipientDetails?.service ==
    SERVICE_TYPE_VALUES.TRANSITION
      ? SPD_COLUMNS_SERVICE_TRANSITION
      : provisionDataSource?.recipientDetails?.service ==
          SERVICE_TYPE_VALUES.TYPE_2
        ? SPD_COLUMNS_SERVICE_B
        : SPD_COLUMNS_SERVICE_A

  const eraMonth = useMemo(() => {
    const month =
      currentQuery?.month < 9 ? `0${currentQuery?.month}` : currentQuery?.month
    const era = wareki(`${currentQuery?.year}-${month}`, {
      unit: true,
    })
    return `${era}${currentQuery?.month?.toString().padStart(2, "0")}月分`
  }, [currentQuery.year, currentQuery.month])

  // props resolver
  const footerProps = footerPropsResolver(currentQuery, userName)
  const headProps = recordSheetHeadPropsResolver(eraMonth, currentQuery)

  const SPD_COLUMNS = useMemo(() => {
    if (eliminateParentCol(currentQuery)) {
      // setting to eliminate guardian column
      // no setting to leave column
      return ORIGINAL_SPD_COLUMNS.filter(
        (col) => col.key != "guardian_confirmation",
      )
    }
    return ORIGINAL_SPD_COLUMNS
  }, [currentQuery.print_settings, ORIGINAL_SPD_COLUMNS])

  const LoadingTab: TabsProps["items"] = useMemo(
    () => [
      {
        key: `loading`,
        label: "Loading...",
        loadingSheet: true,
        children: (
          <Spin spinning={true}>
            <RecordListSheetWrapper>
              <PerformanceRecordSheetHead />
              <Table
                pagination={false}
                scroll={{ x: "max-content" }}
                dataSource={[]}
                columns={SPD_COLUMNS}
                footer={TFooter}
              />
            </RecordListSheetWrapper>
          </Spin>
        ),
      },
    ],
    [],
  )

  return (
    <ServiceRecordTabContainer>
      <Tabs
        activeKey={
          loadingSheet || updatingServiceProvision ? "loading" : userId
        }
        padding={"0px 0px"}
        border={"none"}
        onChange={(activeKey) => onTabChange(activeKey)}
        renderTabBar={() => <div id={"tabs-header_content"}>{userName}</div>}
        items={
          loadingSheet || updatingServiceProvision
            ? LoadingTab
            : [
                {
                  key: userId,
                  label: userName,
                  children: (
                    <RecordListSheetWrapper>
                      <PerformanceRecordSheetHead
                        {...headProps(provisionDataSource)}
                        currentQuery={currentQuery}
                      />
                      {/* Table for screen screen only starts here  */}
                      <Table
                        pagination={false}
                        columns={SPD_COLUMNS}
                        scroll={{ x: "max-content" }}
                        className={"screen-media-table"}
                        dataSource={[]
                          .concat(
                            serviceProvDetailsDataResolver(
                              provisionDataSource.dataSource || [],
                            ),
                          )
                          .concat([provisionDataSource.tableSummary])}
                        rowClassName={(record) =>
                          !record?.id ? "empty-row" : ""
                        }
                        components={{
                          body: {
                            wrapper: (props) => (
                              <TBody
                                oneCellDown={eliminateParentCol(currentQuery)}
                                {...props}
                              />
                            ),
                          },
                        }}
                        footer={() => (
                          <TFooter {...footerProps(provisionDataSource)} />
                        )}
                      />
                      {/* Table for screen screen only ends here  */}

                      {/* Table for print screen only starts here  */}
                      <PrintFriendlyTable
                        className={"hide-not-print"}
                        columns={SPD_COLUMNS}
                        scroll={{ x: "500px" }}
                        dataSource={[]
                          .concat(
                            serviceProvDetailsDataResolver(
                              provisionDataSource.dataSource || [],
                            ),
                          )
                          .concat([provisionDataSource.tableSummary])}
                        rowClassName={(record) =>
                          !record?.id ? "empty-row" : ""
                        }
                        components={{
                          body: {
                            wrapper: (props) => (
                              <TBody
                                oneCellDown={eliminateParentCol(currentQuery)}
                                {...props}
                              />
                            ),
                          },
                        }}
                        footer={() => (
                          <TFooter {...footerProps(provisionDataSource)} />
                        )}
                      />
                      {/* Table for print screen only ends here  */}
                    </RecordListSheetWrapper>
                  ),
                },
              ]
        }
      />
    </ServiceRecordTabContainer>
  )
}

export const PerformanceRecordSheetHead = ({
  recipientNumber = "0000000000",
  guardianAndChildName,
  officeNumber = "0000000000",
  contractAmount,
  businessOwner,
  eraMonth,
  formValue,
  service = 0,
  currentQuery,
}: {
  recipientNumber?: string
  guardianAndChildName?: string
  officeNumber?: string
  contractAmount?: string
  businessOwner?: string
  eraMonth?: string
  formValue?: string
  disabledChildLabel?: string
  service?: number
  currentQuery?: ServiceProvDetailsOperation
}) => {
  const cellsForOfficeNumber = Math.max(10, officeNumber?.length)
  const getRecordSheetTitle = () => {
    if (
      service == SERVICE_TYPE_VALUES.TYPE_1 ||
      service == SERVICE_TYPE_VALUES.TYPE_2
    )
      return t("Employment continuity support provision record sheet")
    if (service == SERVICE_TYPE_VALUES.TRANSITION)
      return t("Employment transition support provision record sheet")
    return null
  }
  return (
    <Box mb={"4px"} className={"performance-record-container"}>
      <StyledBox c={"text"} fz={"xs"} mb={"4xs"}>
        <DateEra>{eraMonth}</DateEra>
        <h5 className={"typography-title-h5"}>{getRecordSheetTitle()}</h5>
        {formValue && <FormNumber>{formValue}</FormNumber>}
      </StyledBox>

      {/* Recipient info table starts here */}
      <Box ta={"center"}>
        <TableUpperSection className={"top-comp-table"}>
          <table className={"table"}>
            <thead className={"service-prov-details-thead"}>
              <tr className={"service-prov-details-row"}>
                <th className={"head"}>{t("Beneficiary ID number")}</th>
                {recipientNumber
                  ?.padStart(10, " ")
                  .split("")
                  .map((num, index) => (
                    <td
                      key={index}
                      className={"base-cell"}
                      style={{
                        minWidth: "21px",
                      }}
                    >
                      {num}
                    </td>
                  ))}
                <th className={"head"}>
                  {t("支給決定{{dynamicText}}", {
                    dynamicText: currentQuery?.print_settings.includes(
                      PRINT_SETTINGS_VALUES.CHANGE_DISABLE_CHILD_NAME_VALUE,
                    )
                      ? "利用者氏名"
                      : "障害者氏名",
                  })}
                </th>
                <td className={"guardian-child-name"}>
                  {guardianAndChildName}
                </td>
                <th className={"head office-number"}>{t("Office Number")}</th>

                {officeNumber
                  ?.padStart(cellsForOfficeNumber, " ")
                  ?.split("")
                  ?.map((num, index) => (
                    <td
                      key={index}
                      className={"base-cell"}
                      style={{
                        minWidth: "21px",
                      }}
                    >
                      {num}
                    </td>
                  ))}
              </tr>
              <tr>
                <th className={"head"}>{t("Contract amount")}</th>
                <td colSpan={12} className={"base-cell"}>
                  {contractAmount}
                  {"日"}
                </td>
                <th>{t("Business operators and their offices")}</th>
                <td colSpan={cellsForOfficeNumber} className={"left base-cell"}>
                  {businessOwner}
                </td>
              </tr>
            </thead>
          </table>
        </TableUpperSection>
      </Box>
      {/* Recipient info table ends here */}
    </Box>
  )
}
