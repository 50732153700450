import {
  ServiceProvOperation,
  ServiceProvDetailsOperation,
} from "../../types/serviceRecordProvision"

export const SERVICE_PROV_DETAILS_KEYS = [
  "year",
  "month",
  "print_settings",
  "remark_contents",
  "additional_items",
  "has_actual_expenses",
  "actual_expenses_display_setting",
  "has_reason_for_charge",
  "reason_for_charge_display_setting",
  "absence_info",
  "display_digital_sign",
  "add_signature_line",
] as (keyof ServiceProvDetailsOperation)[]

export const SERVICE_PROV_INTERNAL_KEYS = [
  "keyword",
  "facility_ids",
  "username_id",
  "display_types",
  "use_service",
  "year",
  "month",
] as (keyof ServiceProvOperation)[]
