import { PRINT_SETTINGS_VALUES } from "@project/common/src/constants"
import { ServiceProvDetailsOperation } from "../../../types/serviceRecordProvision"

export const eliminateParentCol = (query: ServiceProvDetailsOperation) =>
  query.print_settings.includes(
    PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE,
  ) &&
  !query.print_settings.includes(
    PRINT_SETTINGS_VALUES.LEAVE_FIELD_FOR_GUARDIAN_CONFIRMATION_VALUE,
  )
