import { t } from "i18next"

// styles
import { Box, SERVICE_TYPE_VALUES } from "@project/common"
import dayjs from "dayjs"
import {
  ConfirmationContentFooter,
  HowManyOutOfHowManyFooter,
  ParentConfirmationContent,
  StyledTFooter,
} from "./ServiceProvDetailsInternal.styles"

export const TFooter = (props) => {
  const {
    userName = "",
    amountPaid = 0,
    numberOfApplications = 0,
    daysUsed = 0,
    daysAbsent = 0,
    daysCancelled = 0,
    payoutRate = 0,
    utilizationRate = 0,
    absenceRate = 0,
    cancellationRate = 0,
    showConfirmationContent = false,
    showColumnTable = false,
    showParentConfirmationAtBottom = false,
    showDateAtParentConfirmation = false,
    showUserNameAtParentConfirmation = false,
    showParentSignatureAtParentConfirmation = false,
    showSealAtParentConfirmation = false,
    intensiveSupportAddition = "",
    initialAdditionStartDate = "",
    initialAdditionEndDate = "",
    calculationDaysForCurrentMonth = 0,
    userService,
  } = props
  let number_of_sections = 0
  if (showDateAtParentConfirmation) number_of_sections++
  if (showParentSignatureAtParentConfirmation) number_of_sections++
  return (
    <div className={"footer-container"}>
      <StyledTFooter className={"footer-table"}>
        <tbody>
          <tr>
            <td>{t("Initial addition")}</td>
            <td>{t("Start date of use")}</td>
            <td>
              {initialAdditionStartDate &&
              dayjs(initialAdditionStartDate).isValid()
                ? dayjs(initialAdditionStartDate).format("YYYY年MM月DD日")
                : ""}
            </td>
            <td>{t("30日目")}</td>
            <td>
              {initialAdditionEndDate && dayjs(initialAdditionEndDate).isValid()
                ? dayjs(initialAdditionEndDate).format("YYYY年MM月DD日")
                : ""}
            </td>
            <td>{t("Calculation days for the current month")}</td>
            <td>
              {calculationDaysForCurrentMonth
                ? calculationDaysForCurrentMonth
                : ""}
            </td>
          </tr>
          <tr>
            <td>{t("Intensive support addition")}</td>
            <td>{t("Support start date")}</td>
            <td>
              {intensiveSupportAddition &&
              dayjs(intensiveSupportAddition).isValid()
                ? dayjs(intensiveSupportAddition).format("YYYY年MM月DD日")
                : ""}
            </td>
          </tr>
        </tbody>
      </StyledTFooter>

      {showParentConfirmationAtBottom && (
        <ParentConfirmationContent number_of_sections={number_of_sections}>
          {showDateAtParentConfirmation && (
            <div className={"bottom-date"}>{"     年   月   日"}</div>
          )}
          <div className={"child-name"}>
            {t("name ")} &nbsp;&nbsp;&nbsp;
            {showUserNameAtParentConfirmation && userName}
          </div>
          {showParentSignatureAtParentConfirmation && (
            <div className={"parent-signature"}></div>
          )}
          {showSealAtParentConfirmation && (
            <div className={"seal-col"}>{t("mark")}</div>
          )}
        </ParentConfirmationContent>
      )}

      {showConfirmationContent && (
        <ConfirmationContentFooter>
          {[SERVICE_TYPE_VALUES.TYPE_1, SERVICE_TYPE_VALUES.TYPE_2].includes(
            userService,
          )
            ? t(
                "As stated above, we have confirmed that we have received employment continuity support.",
              )
            : userService == SERVICE_TYPE_VALUES.TRANSITION
              ? t(
                  "As stated above, we have confirmed that we have received employment transition support.",
                )
              : ""}
        </ConfirmationContentFooter>
      )}
      {showColumnTable && (
        <HowManyOutOfHowManyFooter>
          <table className={"column-table"}>
            <tbody>
              <tr>
                <td></td>
                <td>{"1"}</td>
                <td>{t("In the middle of the day")}</td>
                <td></td>
                <td>{"1"}</td>
                <td>{t("The second piece")}</td>
              </tr>
            </tbody>
          </table>
        </HowManyOutOfHowManyFooter>
      )}
      <Box className={"bottom-section-first"} miw={700} mt={"xmd"} fz={15}>
        {t("buttomSectionFirst", {
          amount: amountPaid,
          applicant: numberOfApplications,
          used_day: daysUsed,
          absence: daysAbsent,
          cancel: daysCancelled,
        })}
        <br />
        {t("buttomSectionsecond", {
          payout: payoutRate,
          utilization: utilizationRate,
          absence: absenceRate,
          cancel: cancellationRate,
        })}
      </Box>
    </div>
  )
}
