// packages
import { t } from "i18next"

// types
import { ColumnsType } from "antd/es/table"
import { SPDTableColumns } from "../../../types/serviceRecordProvision"

export const SPD_COLUMNS_SERVICE_A: ColumnsType<SPDTableColumns> = [
  {
    title: t("Day"),
    dataIndex: "day",
    key: "day",
    align: "center",
    width: 50,
  },
  {
    title: t("Day of the week"),
    dataIndex: "day_of_the_week",
    key: "day_of_the_week",
    align: "center",
    width: 53,
  },

  {
    title: t("Service provision result"),
    children: [
      {
        title: t("Service Provision status"),
        dataIndex: "service_provision_status",
        key: "service_provision_status",
        width: 100,
        align: "center",
      },
      {
        title: t("Start time"),
        dataIndex: "start_time",
        key: "start_time",
        width: 100,
        align: "center",
      },
      {
        title: t("End time"),
        dataIndex: "end_time",
        key: "end_time",
        width: 100,
        align: "center",
      },
      {
        title: t("Additional transportation fee"),
        align: "center",
        children: [
          {
            title: t("Past"),
            dataIndex: "past",
            key: "past",
            width: 50,
            align: "center",
          },
          {
            title: t("Complex"),
            dataIndex: "complex",
            key: "complex",
            width: 50,
            align: "center",
          },
        ],
      },
      {
        title: t("Visit support special addition"),
        align: "center",
        children: [
          {
            title: t("Number of hours"),
            dataIndex: "number_of_hours",
            key: "number_of_hours",
            width: 100,
            align: "center",
          },
        ],
      },
      {
        title: t("Meal provision addition"),
        dataIndex: "meal_provision",
        key: "meal_provision",
        width: 70,
        align: "center",
      },
      {
        title: t("Medical cooperation system addition"),
        dataIndex: "medical_cooperation",
        key: "medical_cooperation",
        width: 70,
        align: "center",
      },
      {
        title: t("Trial use support addition"),
        dataIndex: "trial_use_support",
        key: "trial_use_support",
        width: 70,
        align: "center",
      },
      {
        title: t("Emergency acceptance addition"),
        dataIndex: "emergency_acceptance_addition",
        key: "emergency_acceptance_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Intensive Support Addition"),
        dataIndex: "intensive_support_addition",
        key: "intensive_support_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Off-site support"),
        dataIndex: "off_site_support",
        key: "off_site_support",
        width: 70,
        align: "center",
      },
    ],
  },

  {
    title: t("User Confirmation column"),
    dataIndex: "guardian_confirmation",
    key: "guardian_confirmation",
    width: 175,
    align: "center",
  },
  {
    title: t("Remark"),
    dataIndex: "remarks",
    key: "remarks",
    width: 175,
    align: "center",
  },
]

export const SPD_COLUMNS_SERVICE_B: ColumnsType<SPDTableColumns> = [
  {
    title: t("Day"),
    dataIndex: "day",
    key: "day",
    align: "center",
    width: 50,
  },
  {
    title: t("Day of the week"),
    dataIndex: "day_of_the_week",
    key: "day_of_the_week",
    align: "center",
    width: 53,
  },

  {
    title: t("Service provision result"),
    children: [
      {
        title: t("Service Provision status"),
        dataIndex: "service_provision_status",
        key: "service_provision_status",
        width: 100,
        align: "center",
      },
      {
        title: t("Start time"),
        dataIndex: "start_time",
        key: "start_time",
        width: 100,
        align: "center",
      },
      {
        title: t("End time"),
        dataIndex: "end_time",
        key: "end_time",
        width: 100,
        align: "center",
      },
      {
        title: t("Additional transportation fee"),
        align: "center",
        children: [
          {
            title: t("Past"),
            dataIndex: "past",
            key: "past",
            width: 50,
            align: "center",
          },
          {
            title: t("Complex"),
            dataIndex: "complex",
            key: "complex",
            width: 50,
            align: "center",
          },
        ],
      },
      {
        title: t("Visit support special addition"),
        align: "center",
        children: [
          {
            title: t("Number of hours"),
            dataIndex: "number_of_hours",
            key: "number_of_hours",
            width: 100,
            align: "center",
          },
        ],
      },
      {
        title: t("Meal provision addition"),
        dataIndex: "meal_provision",
        key: "meal_provision",
        width: 70,
        align: "center",
      },
      {
        title: t("Medical cooperation system addition"),
        dataIndex: "medical_cooperation",
        key: "medical_cooperation",
        width: 70,
        align: "center",
      },
      {
        title: t("Trial use support addition"),
        dataIndex: "trial_use_support",
        key: "trial_use_support",
        width: 70,
        align: "center",
      },
      {
        title: t("Regional collaboration"),
        dataIndex: "regional_collaboration",
        key: "regional_collaboration",
        width: 70,
        align: "center",
      },
      {
        title: t("Emergency acceptance addition"),
        dataIndex: "emergency_acceptance_addition",
        key: "emergency_acceptance_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Intensive Support Addition"),
        dataIndex: "intensive_support_addition",
        key: "intensive_support_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Off-site support"),
        dataIndex: "off_site_support",
        key: "off_site_support",
        width: 70,
        align: "center",
      },
    ],
  },

  {
    title: t("User Confirmation column"),
    dataIndex: "guardian_confirmation",
    key: "guardian_confirmation",
    width: 175,
    align: "center",
  },
  {
    title: t("Remark"),
    dataIndex: "remarks",
    key: "remarks",
    width: 175,
    align: "center",
  },
]

export const SPD_COLUMNS_SERVICE_TRANSITION: ColumnsType<SPDTableColumns> = [
  {
    title: t("Day"),
    dataIndex: "day",
    key: "day",
    align: "center",
    width: 50,
  },
  {
    title: t("Day of the week"),
    dataIndex: "day_of_the_week",
    key: "day_of_the_week",
    align: "center",
    width: 53,
  },

  {
    title: t("Service provision result"),
    children: [
      {
        title: t("Service Provision status"),
        dataIndex: "service_provision_status",
        key: "service_provision_status",
        width: 100,
        align: "center",
      },
      {
        title: t("Start time"),
        dataIndex: "start_time",
        key: "start_time",
        width: 100,
        align: "center",
      },
      {
        title: t("End time"),
        dataIndex: "end_time",
        key: "end_time",
        width: 100,
        align: "center",
      },
      {
        title: t("Additional transportation fee"),
        align: "center",
        children: [
          {
            title: t("Past"),
            dataIndex: "past",
            key: "past",
            width: 50,
            align: "center",
          },
          {
            title: t("Complex"),
            dataIndex: "complex",
            key: "complex",
            width: 50,
            align: "center",
          },
        ],
      },
      {
        title: t("Visit support special addition"),
        align: "center",
        children: [
          {
            title: t("Number of hours"),
            dataIndex: "number_of_hours",
            key: "number_of_hours",
            width: 100,
            align: "center",
          },
        ],
      },
      {
        title: t("Meal provision addition"),
        dataIndex: "meal_provision",
        key: "meal_provision",
        width: 70,
        align: "center",
      },
      {
        title: t("Medical cooperation system addition"),
        dataIndex: "medical_cooperation",
        key: "medical_cooperation",
        width: 70,
        align: "center",
      },
      {
        title: t("Commuting training addition"),
        dataIndex: "commuting_addition",
        key: "commuting_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Trial use support addition"),
        dataIndex: "trial_use_support",
        key: "trial_use_support",
        width: 70,
        align: "center",
      },
      {
        title: t("Regional collaboration meeting implementation addition"),
        dataIndex: "regional_collaboration_meeting_implementation",
        key: "regional_collaboration_meeting_implementation",
        width: 70,
        align: "center",
      },
      {
        title: t("Emergency acceptance addition"),
        dataIndex: "emergency_acceptance_addition",
        key: "emergency_acceptance_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Intensive Support Addition"),
        dataIndex: "intensive_support_addition",
        key: "intensive_support_addition",
        width: 70,
        align: "center",
      },
      {
        title: t("Preparing for migration Support system addition"),
        dataIndex: "off_site_support",
        key: "off_site_support",
        width: 70,
        align: "center",
      },
    ],
  },

  {
    title: t("User Confirmation column"),
    // NOTE: previously title was "Confirmation columns for guardians, etc"
    // hence the dataIndex name: `guardian_confirmation`
    // Later the title is changed to "User Confirmation column"
    // now changing `dataIndex` based off of the title will require us to update `key` for this column in multiple
    // instances, thats why i'm leaving it as it is. It should work fine for now, later if you want you can change it as required.
    dataIndex: "guardian_confirmation",
    key: "guardian_confirmation",
    width: 175,
    align: "center",
  },
  {
    title: t("Remark"),
    dataIndex: "remarks",
    key: "remarks",
    width: 175,
    align: "center",
  },
]
