export const getSplittedTime = (time: string) => {
  const t = time ? time : "00:00"
  const [hr, min] = t.split(":")
  return { hr, min: min ? min : null }
}

export const getHrMin = (time?: string | null) => {
  const splittedTime = time ? time.split(":") : null
  return splittedTime
    ? { hr: splittedTime[0], min: splittedTime[1] }
    : { hr: null, min: null }
}
